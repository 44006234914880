import { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Search from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import usePiutang from "../../services/queries/usePiutang";
import useDebounceFn from "../../hooks/useDebounceFn";
import PiutangCard from "./PiutangCard";
import { format } from "date-fns";
import Box from "@mui/material/Box";
import { useTransaksi } from "../../context/transaksiContext";
import { PiutangParams } from "../../constants/types";

type Props = {
    params: PiutangParams;
    setParams: React.Dispatch<React.SetStateAction<PiutangParams>>;
};

export default function DaftarPiutang({ params, setParams }: Props) {
    const debounceSetParams = useDebounceFn(setParams);
    const query = usePiutang(params);
    const { selectedDebt, setSelectedDebt } = useTransaksi();

    const someDue = useMemo(() => {
        if (!query.data) return false;
        return query.data.content.some((debt) => {
            if (!debt.jatuhTempo) return false;
            const todayStr = format(new Date(), "yyyy-MM-dd");
            const dueDateStr = format(new Date(debt.jatuhTempo), "yyyy-MM-dd");
            return todayStr > dueDateStr;
        });
    }, [query.data]);

    return (
        <>
            <Grid container spacing={2} p={2}>
                <Grid item xs={8}>
                    <TextField
                        size="small"
                        fullWidth
                        placeholder="Cari nomor invoice, nama pelanggan"
                        InputProps={{ endAdornment: <Search /> }}
                        onChange={(e) => {
                            debounceSetParams((prev) => ({
                                ...prev,
                                search: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        size="small"
                        fullWidth
                        placeholder="Urutkan"
                        defaultValue={2}
                        onChange={(e) => {
                            setParams((prev) => ({
                                ...prev,
                                urutan: e.target.value as number,
                            }));
                        }}
                    >
                        <MenuItem value={1}>Jatuh Tempo: Terakhir</MenuItem>
                        <MenuItem value={2}>Jatuh Tempo: Terawal</MenuItem>
                        <MenuItem value={3}>
                            Pembayaran Terakhir: Terbaru
                        </MenuItem>
                        <MenuItem value={4}>
                            Pembayaran Terakhir: Terlama
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {query.isLoading ? (
                <Stack alignItems="center">
                    <CircularProgress />
                </Stack>
            ) : query.isError ? (
                <Alert severity="error">Terjadi kesalahan.</Alert>
            ) : (
                <>
                    {someDue && (
                        <Box px={2} pb={2}>
                            <Alert severity="error">
                                Ada piutang yang jatuh tempo.
                            </Alert>
                        </Box>
                    )}
                    <RadioGroup
                        value={selectedDebt}
                        onChange={(e) => {
                            setSelectedDebt(+e.target.value);
                        }}
                        sx={{
                            overflowY: "auto",
                            flexWrap: "nowrap",
                            gap: 2,
                            px: 2,
                            pb: 2,
                        }}
                    >
                        {query.data?.content.map((debt) => (
                            <PiutangCard
                                key={debt.nomorInvoice}
                                id={debt.id}
                                invoiceNumber={debt.nomorInvoice}
                                customerName={debt.pelangganNama ?? ""}
                                remainingDebt={debt.sisapiutang}
                                dueDate={debt.jatuhTempo}
                                recentPayment={debt.tanggal}
                                selected={selectedDebt === debt.id}
                            />
                        ))}
                    </RadioGroup>
                </>
            )}
        </>
    );
}
