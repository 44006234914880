import { useQuery } from "react-query";
import {
    RiwayatPiutangParams,
    RiwayatPiutangResponse,
} from "../../constants/types";
import axios from "../axios";

export default function useRiwayatPiutang(params: RiwayatPiutangParams) {
    return useQuery(["riwayat-piutang", params], async () => {
        const res = await axios.get<RiwayatPiutangResponse>(
            "/api/pembayaran-transaksis",
            { params },
        );
        return res.data.data;
    });
}
