import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    DialogTitle,
    Typography,
    Divider,
} from "@mui/material";
import React from "react";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useLogoutModal } from "../../context/logoutModalContext";
import { textPrimary } from "../../constants/colors";

interface IModalKonfirmasiLogoutProps {}

const ModalKonfirmasiLogout = (props: IModalKonfirmasiLogoutProps) => {
    const { logout, isLoading } = useAuth();
    const { handleReset } = useTransaksi();
    const { isOpenModalLogout, closeModalLogout } = useLogoutModal();

    const handleKeluar = () => {
        handleReset();
        logout();
        closeModalLogout();
    };

    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={isOpenModalLogout}
                onClose={closeModalLogout}
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Typography fontSize={32} fontWeight={700}>
                        Konfirmasi Keluar
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText color={textPrimary.body}>
                        Anda yakin ingin keluar dari akun dan kembali ke halaman
                        masuk?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button variant="outlined" onClick={closeModalLogout}>
                        Kembali
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleKeluar}
                        color="error"
                    >
                        Konfirmasi Keluar
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingOverlay open={isLoading} />
        </>
    );
};

export default ModalKonfirmasiLogout;
