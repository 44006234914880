import React from "react";
import useUkm from "../../services/queries/useUkm";
import { useAuth } from "../../context/authContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { idID } from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import Spinner from "../Spinner/Spinner";
import { defaultColor } from "../../constants/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { textPrimary } from "../../constants/colors";

interface IThemeComponentProps {
    children: React.ReactNode;
}

declare module "@mui/material/styles" {
    interface Palette {
        abu: Palette["primary"];
        ulo: Palette["primary"];
        whatsapp: Palette["primary"];
        bolddanger: Palette["primary"];
        greyc4: Palette["primary"];
        lightblue: Palette["primary"];
        buttonblue: Palette["primary"];
        buttonyellow: Palette["primary"];
        buttonred: Palette["primary"];
        buttongreen: Palette["primary"];
        buttonulo: Palette["primary"];
        lightwarning: Palette["primary"];
        lighterror: Palette["primary"];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        abu?: PaletteOptions["primary"];
        ulo?: PaletteOptions["primary"];
        whatsapp?: PaletteOptions["primary"];
        bolddanger?: PaletteOptions["primary"];
        greyc4?: PaletteOptions["primary"];
        lightblue?: PaletteOptions["primary"];
        buttonblue?: PaletteOptions["primary"];
        buttonyellow?: PaletteOptions["primary"];
        buttonred?: PaletteOptions["primary"];
        buttongreen?: PaletteOptions["primary"];
        buttonulo?: PaletteOptions["primary"];
        lightwarning?: PaletteOptions["primary"];
        lighterror?: PaletteOptions["primary"];
    }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        abu: true;
        ulo: true;
        whatsapp: true;
        bolddanger: true;
        greyc4: true;
        lightblue: true;
        buttonblue: true;
        buttonyellow: true;
        buttonred: true;
        buttongreen: true;
        buttonulo: true;
        lightwarning: true;
        lighterror: true;
    }
}

const ThemeComponent = ({ children }: IThemeComponentProps) => {
    const { ukmIdUser } = useAuth();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm, status: statusUkm } = useUkm(initialParamsUkm);

    const ukmId = React.useMemo(() => {
        if (ukmIdUser) {
            return ukmIdUser;
        }
        return null;
    }, [ukmIdUser]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId[0]);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const theme = createTheme(
        {
            palette: {
                primary: {
                    main: ukmData?.warna ?? defaultColor,
                    // light: "#6AB893",
                    // dark: "#307454",
                    contrastText: "#fff",
                },
                secondary: {
                    main: "#ffd600",
                },
                // background: {
                //     default: "#F5F5F4",
                // },
                abu: {
                    main: "#fff",
                },
                ulo: {
                    main: defaultColor,
                    contrastText: "#fff",
                },
                whatsapp: {
                    main: "#399F3A",
                    contrastText: "#fff",
                },
                bolddanger: {
                    main: "#E52828",
                },
                greyc4: {
                    main: "#C4C4C4",
                    contrastText: "#fff",
                },
                lightblue: {
                    main: "#1976D2",
                },
                buttonblue: {
                    main: "#40A0F9",
                    contrastText: "#fff",
                },
                buttonyellow: {
                    main: "#FFC46C",
                    contrastText: "#fff",
                },
                buttonred: {
                    main: "#FF7373",
                    contrastText: "#fff",
                },
                buttongreen: {
                    main: "#119C5B",
                    contrastText: "#fff",
                },
                buttonulo: {
                    main: "#45a779 ",
                    contrastText: "#fff",
                },
                lightwarning: {
                    main: "#ED6C02",
                    contrastText: "#fff",
                },
                lighterror: {
                    main: "#D32F2F",
                    contrastText: "#fff",
                },
            },
            typography: {
                fontFamily: [
                    "Inter",
                    "-apple-system",
                    "BlinkMacSystemFont",
                    '"Segoe UI"',
                    "Roboto",
                    '"Helvetica Neue"',
                    "Arial",
                    "sans-serif",
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(","),
                fontWeightMedium: 500,
                fontWeightBold: 700,
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    styleOverrides: {
                        root: {
                            borderRadius: "8px",
                            textTransform: "none",
                            fontSize: 14,
                            fontWeight: 500,
                            padding: "12px 24px",
                            "&.Mui-disabled": {
                                color: "#fff",
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            borderRadius: "8px",
                        },
                    },
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#fff",
                        },
                        inputRoot: {
                            borderRadius: "8px",
                        },
                        popupIndicator: {
                            "& svg": {
                                "& path": {
                                    d: "path('M7.41 8.57996L12 13.17L16.59 8.57996L18 9.99996L12 16L6 9.99996L7.41 8.57996Z')",
                                },
                            },
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                            },
                            "& .MuiInputBase-root": {
                                color: textPrimary.body,
                            },
                        },
                    },
                },
                MuiSelect: {
                    defaultProps: {
                        IconComponent: ExpandMoreIcon,
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        root: ({ _, theme }) => ({
                            width: 42,
                            height: 26,
                            padding: 0,
                            marginRight: 12,
                            marginLeft: 12,
                            "& .MuiSwitch-switchBase": {
                                padding: 0,
                                margin: 2,
                                transitionDuration: "300ms",
                                "&.Mui-checked": {
                                    transform: "translateX(16px)",
                                    color: "#fff",
                                    "& + .MuiSwitch-track": {
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        opacity: 1,
                                        border: 0,
                                    },
                                    "&.Mui-disabled + .MuiSwitch-track": {
                                        opacity: 0.5,
                                    },
                                },
                                "&.Mui-focusVisible .MuiSwitch-thumb": {
                                    color: "#33cf4d",
                                    border: "6px solid #fff",
                                },
                                "&.Mui-disabled .MuiSwitch-thumb": {
                                    color:
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[600],
                                },
                                "&.Mui-disabled + .MuiSwitch-track": {
                                    opacity:
                                        theme.palette.mode === "light"
                                            ? 0.7
                                            : 0.3,
                                },
                            },
                            "& .MuiSwitch-thumb": {
                                boxSizing: "border-box",
                                width: 22,
                                height: 22,
                            },
                            "& .MuiSwitch-track": {
                                borderRadius: 26 / 2,
                                backgroundColor: "#E9F2F0",
                                opacity: 1,
                                transition: theme.transitions.create(
                                    ["background-color"],
                                    {
                                        duration: 500,
                                    },
                                ),
                            },
                        }),
                    },
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            textTransform: "none",
                            fontWeight: 500,
                        },
                    },
                },
                MuiTabs: {
                    styleOverrides: {
                        root: ({ _, theme }) => ({
                            "& .MuiTabs-indicator": {
                                height: "4px",
                            },
                        }),
                    },
                },
                MuiToggleButton: {
                    styleOverrides: {
                        root: ({ _, theme }) => ({
                            borderRadius: "8px",
                            color: theme.palette.primary.main,
                            border: `2px solid ${theme.palette.primary.main}`,
                            "&.Mui-selected": {
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                border: `2px solid ${theme.palette.primary.main}`,
                                borderRadius: "8px",
                            },
                        }),
                    },
                },
            },
        },
        idID,
    );

    if (statusUkm === "loading") {
        return (
            <Box position="relative" height="100vh">
                <Spinner />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeComponent;
