import {
    Stack,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    Grid,
    IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import LoginImage from "../../assets/images/IlustrasiMainMenu.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import useNoWaAdmin from "../../services/queries/useNoWaAdmin";
import { ReactComponent as FAQImage } from "../../assets/images/FAQImage.svg";
import { textPrimary } from "../../constants/colors";

interface IFAQProps {}

const FAQ = (props: IFAQProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const { data: noWaAdmin } = useNoWaAdmin();

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Box
            display={isTabletScreen ? "flex" : "grid"}
            gridTemplateColumns={isTabletScreen ? undefined : "1fr 2fr"}
            flexDirection="column"
            justifyContent="center"
            gap={2}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <FAQImage
                    width={
                        isTabletScreen ? "70%" : isLaptopScreen ? "90%" : 400
                    }
                    style={{ height: "fit-content" }}
                />
            </Box>
            <Stack spacing={3}>
                <Typography
                    color={textPrimary.body}
                    fontSize={24}
                    fontWeight={600}
                    textAlign="center"
                >
                    Pertanyaan yang sering diajukan
                </Typography>
                <Box>
                    <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        elevation={0}
                        disableGutters
                        sx={{
                            border: `1px solid rgba(0,0,0,0.12)`,
                            "&:not(:last-child)": {
                                borderBottom: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography
                                sx={{
                                    width: "80%",
                                    flexShrink: 0,
                                }}
                                fontWeight={600}
                                color="primary"
                            >
                                Apakah keuntungan menggunakan ULO?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                borderTop: "1px solid rgba(0,0,0,0.12)",
                            }}
                        >
                            <Stack
                                component="ol"
                                pl={2.5}
                                color={textPrimary.body}
                                fontSize={14}
                            >
                                <Typography component="li">
                                    Harga yang terjangkau,hanya dengan 600rb
                                    perTahun dapat menggunakan POS sistem dengan
                                    teknologi sistem terbaru.
                                </Typography>
                                <Typography component="li">
                                    Sangat mudah digunakan.
                                </Typography>
                                <Typography component="li">
                                    Tidak monoton dan membuat pengguna betah
                                    didepan layar.
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                        elevation={0}
                        disableGutters
                        sx={{
                            border: `1px solid rgba(0,0,0,0.12)`,
                            "&:not(:last-child)": {
                                borderBottom: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography
                                sx={{
                                    width: "80%",
                                    flexShrink: 0,
                                }}
                                fontWeight={600}
                                color="primary"
                            >
                                Sampai berapa lama mutasi transaksi dapat
                                dilihat?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                borderTop: "1px solid rgba(0,0,0,0.12)",
                            }}
                        >
                            <Stack color={textPrimary.body} fontSize={14}>
                                <Typography>
                                    Pengguna FREE hanya dapat melihat daftar
                                    mutasi hingga 3 bulan terakhir, sedangkan
                                    pengguna PRO dapat melihat daftar mutasi
                                    tanpa ada batasan.
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                        elevation={0}
                        disableGutters
                        sx={{
                            border: `1px solid rgba(0,0,0,0.12)`,
                            "&:not(:last-child)": {
                                borderBottom: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography
                                sx={{
                                    width: "80%",
                                    flexShrink: 0,
                                }}
                                fontWeight={600}
                                color="primary"
                            >
                                Apakah transaksi dapat menggunakan metode lain
                                seperti transfer bank atau QRIS?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                borderTop: "1px solid rgba(0,0,0,0.12)",
                            }}
                        >
                            <Stack color={textPrimary.body} fontSize={14}>
                                <Typography>
                                    Bisa. Hanya dengan menggunakan nama dan
                                    nomor rekening kita dapat mempermudah sistem
                                    pembayaran.
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                        elevation={0}
                        disableGutters
                        sx={{
                            border: `1px solid rgba(0,0,0,0.12)`,
                            "&:not(:last-child)": {
                                borderBottom: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography
                                sx={{
                                    width: "80%",
                                    flexShrink: 0,
                                }}
                                fontWeight={600}
                                color="primary"
                            >
                                Berapa total jenis barang yang dapat diinput
                                dalam ULO?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                borderTop: "1px solid rgba(0,0,0,0.12)",
                            }}
                        >
                            <Stack color={textPrimary.body} fontSize={14}>
                                <Typography>
                                    10 jenis barang untuk pengguna FREE, 500
                                    jenis barang untuk pengguna PRO.
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                        elevation={0}
                        disableGutters
                        sx={{
                            border: `1px solid rgba(0,0,0,0.12)`,
                            "&:not(:last-child)": {
                                borderBottom: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography
                                sx={{
                                    width: "80%",
                                    flexShrink: 0,
                                }}
                                fontWeight={600}
                                color="primary"
                            >
                                Apakah ULO dapat digunakan oleh usaha yang
                                bergerak dibidang jasa?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                borderTop: "1px solid rgba(0,0,0,0.12)",
                            }}
                        >
                            <Stack color={textPrimary.body} fontSize={14}>
                                <Typography>
                                    Sangat bisa! Hanya dengan menonaktifkan
                                    jumlah stok barang saja.
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel5"}
                        onChange={handleChange("panel5")}
                        elevation={0}
                        disableGutters
                        sx={{
                            border: `1px solid rgba(0,0,0,0.12)`,
                            "&:not(:last-child)": {
                                borderBottom: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography
                                sx={{
                                    width: "80%",
                                    flexShrink: 0,
                                }}
                                fontWeight={600}
                                color="primary"
                            >
                                Apakah struk transaksi di ULO dapat di cetak?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                borderTop: "1px solid rgba(0,0,0,0.12)",
                            }}
                        >
                            <Stack color={textPrimary.body} fontSize={14}>
                                <Typography>
                                    Bisa, dengan menggunakan{" "}
                                    <i>thermal printer</i>. Apabila pengguna
                                    menggunakan perangkat tablet/handphone dapat
                                    disambungkan melalui Bluetooth, sedangkan
                                    jika pengguna menggunakan Laptop atau PC
                                    dapat menggunakan kabel tambahan.
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Stack spacing={2} alignItems="center">
                    <Typography
                        color={textPrimary.title}
                        fontSize={20}
                        fontWeight={500}
                    >
                        Kontak Kami:
                    </Typography>
                    <Box maxWidth={400}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <IconButton
                                        color="primary"
                                        href={`https://wa.me/${noWaAdmin?.nomor}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <WhatsAppIcon />
                                    </IconButton>
                                    <Typography>+{noWaAdmin?.nomor}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <IconButton
                                        color="primary"
                                        href="https://www.facebook.com/Nore-110554210353267"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FacebookIcon />
                                    </IconButton>
                                    <Typography>@norewebid</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <IconButton
                                        color="primary"
                                        href="mailto:cs@nore.web.id?subject=Pertanyaan mengenai UKM Lokal"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <EmailIcon />
                                    </IconButton>
                                    <Typography>cs@nore.web.id</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <IconButton
                                        color="primary"
                                        href="https://www.instagram.com/nore.web.id/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <InstagramIcon />
                                    </IconButton>
                                    <Typography>@nore.web.id</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default FAQ;
