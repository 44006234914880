import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    TextField,
    Button,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import axios from "../../services/axios";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EditKategoriBody, ErrorFieldResponse } from "../../constants/types";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { textPrimary } from "../../constants/colors";

const schema = yup
    .object({
        namaKategori: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const ModalEditKategori = () => {
    const {
        isOpenModalEditKategori,
        closeModalEditKategori,
        namaKategori,
        idKategori,
    } = useEditKategoriModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const initialValues = useMemo(
        () => ({
            namaKategori: namaKategori,
        }),
        [namaKategori],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setError,
    } = useForm<EditKategoriBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const queryClient = useQueryClient();

    const onSubmit = async (values: EditKategoriBody) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.put(`/api/subkategori/${idKategori}`, {
                nama: values.namaKategori,
            });
            if (data.code === 200) {
                Swal.fire({
                    title:
                        "Kategori berhasil diubah menjadi " +
                        getValues("namaKategori"),
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            queryClient.invalidateQueries("kategori");
            closeModalEditKategori();
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
        }
    };

    useEffect(() => {
        if (isOpenModalEditKategori) {
            if (namaKategori && idKategori) {
                reset(initialValues);
            }
        }
    }, [
        idKategori,
        initialValues,
        isOpenModalEditKategori,
        namaKategori,
        reset,
    ]);

    return (
        <Dialog
            open={isOpenModalEditKategori}
            fullWidth
            maxWidth="md"
            onClose={closeModalEditKategori}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <Typography
                    color={textPrimary.title}
                    fontSize={32}
                    fontWeight={700}
                >
                    Ubah Kategori
                </Typography>
                <IconButton onClick={closeModalEditKategori}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Typography
                            color={textPrimary.body}
                            fontWeight={500}
                            mb={1}
                        >
                            Nama Kategori
                        </Typography>
                        <Controller
                            name="namaKategori"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="nama"
                                    fullWidth
                                    autoComplete="off"
                                    placeholder="Masukkan nama kategori"
                                    variant="outlined"
                                    error={Boolean(errors.namaKategori)}
                                    helperText={
                                        errors.namaKategori
                                            ? errors.namaKategori.message
                                            : " "
                                    }
                                    {...field}
                                />
                            )}
                            rules={{ required: "Nama Kategori required" }}
                        />
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap={1}
                        mt={2}
                    >
                        <Button
                            type="button"
                            variant="outlined"
                            onClick={closeModalEditKategori}
                        >
                            Kembali
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            variant="contained"
                            type="submit"
                        >
                            Simpan Perubahan
                        </LoadingButton>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEditKategori;
