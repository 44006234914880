export const textPrimary = {
    title: "#2d312f",
    body: "#464e4b",
    light: "#7c8883",
    thin: "#a8b4af",
};

export const textInverted = {
    body: "#e9f2f0",
};
