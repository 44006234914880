import { GetListInvoiceResponse, PiutangParams } from "../../constants/types";
import axios from "../axios";
import { useQuery } from "react-query";

export default function usePiutang(params: PiutangParams) {
    return useQuery(["piutang", params], async () => {
        const res = await axios.get<GetListInvoiceResponse>(
            "/api/laporan/invoice",
            { params },
        );
        return res.data.data;
    });
}
