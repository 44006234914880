import { useQuery } from "react-query";
import { ParamsGetKategori, GetKategoriResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

export default function useKategori(params: ParamsGetKategori) {
    return useQuery<GetKategoriResponse["data"]>(
        ["kategori", params],
        async () => {
            const apiGetKategori = qs.stringifyUrl({
                url: "api/subkategori",
                query: {
                    ukmId: params.ukmId,
                    search: `${params.search}`,
                    size: params.size,
                    page: params.page,
                },
            });
            const { data } = await axios.get<GetKategoriResponse>(
                apiGetKategori,
            );
            return data.data;
        },
        {
            enabled: !!params.ukmId,
        },
    );
}
