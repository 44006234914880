import { useQuery } from "react-query";
import {
    SubCategoriesParams,
    SubCategoriesResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

export default function useSubKategori(params: SubCategoriesParams) {
    return useQuery<SubCategoriesResponse["data"]>(
        ["sub-kategori", params],
        async () => {
            const apiGetSubKategori = qs.stringifyUrl({
                url: "api/kategori",
                query: {
                    ukmId: params.ukmId,
                    search: `${params.search}`,
                    size: params.size,
                    page: params.page,
                    subKategori: params.subKategori,
                },
            });
            const { data } =
                await axios.get<SubCategoriesResponse>(apiGetSubKategori);
            return data.data;
        },
        {
            enabled: !!params.ukmId && !!params.subKategori,
        },
    );
}
