import {
    Autocomplete,
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import axios from "../../services/axios";
import { ReactComponent as TemplateList } from "../../assets/images/TemplateList.svg";
import { ReactComponent as TemplateMenu } from "../../assets/images/TemplateMenu.svg";
import { useAuth } from "../../context/authContext";
import useUkm from "../../services/queries/useUkm";
import useSubCategories from "../../services/queries/useSubCategories";
import { SubCategory } from "../../constants/types";
import useDebounce from "../../hooks/useDebounce";
import { textPrimary } from "../../constants/colors";

export default function BuatKatalog() {
    const [catalogType, setCatalogType] = useState("List");
    const [downloadLoading, setDownloadLoading] = useState(false);
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { ukmIdUser } = useAuth();
    const { data } = useUkm({
        size: 10,
        page: 1,
        search: "",
    });
    const ukm = useMemo(
        () =>
            data?.content.find(
                (li) => li.id === (ukmIdUser ? ukmIdUser[0] : 0),
            ),
        [data?.content, ukmIdUser],
    );

    const [subCategoriesParams, setSubCategoriesParams] = useState({
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        search: "",
    });
    const subCategory = useSubCategories(subCategoriesParams);
    const [selectedSubCategories, setSelectedSubCategories] = useState<
        SubCategory[]
    >([]);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        setSubCategoriesParams((prev) => ({
            ...prev,
            search: debouncedSearch,
        }));
    }, [debouncedSearch]);

    async function download() {
        setDownloadLoading(true);
        if (catalogType === "Excel") {
            const { data } = await axios.post(
                `/api/downloadkatalogexcel`,
                {
                    ukmId: ukmIdUser && ukmIdUser[0],
                    kategori: selectedSubCategories
                        .map((subCategory) => subCategory.id)
                        .join(","),
                },
                {
                    responseType: "blob",
                },
            );
            const file = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `Katalog ${ukm?.namaToko}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        } else {
            const { data } = await axios.post(
                `/api/downloadkatalog`,
                {
                    ukmId: ukmIdUser && ukmIdUser[0],
                    kategori: selectedSubCategories
                        .map((subCategory) => subCategory.id)
                        .join(","),
                    tipe: catalogType,
                },
                {
                    responseType: "blob",
                },
            );
            const file = new Blob([data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
        setDownloadLoading(false);
    }

    return (
        <>
            <Typography
                color={textPrimary.title}
                fontSize={32}
                fontWeight="bold"
                py={2}
            >
                Buat Katalog
            </Typography>
            <Stepper orientation="vertical">
                <Step active>
                    <StepLabel>Pilih Sub Kategori</StepLabel>
                    <StepContent>
                        <Typography color={textPrimary.body} mb={1}>
                            Pilih berbagai sub kategori produk yang hendak
                            disertakan ke dalam katalog.
                        </Typography>
                        <Autocomplete
                            multiple
                            disablePortal
                            filterOptions={(x) => x}
                            loading={subCategory.isLoading}
                            options={subCategory.data?.content ?? []}
                            getOptionLabel={(option) => option.nama}
                            isOptionEqualToValue={(option, value) =>
                                String(option.id) === String(value.id)
                            }
                            value={selectedSubCategories}
                            onChange={(_, value) => {
                                setSelectedSubCategories(value);
                                setSearch("");
                            }}
                            onBlur={() => setSearch("")}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Pilih sub kategori"
                                    variant="outlined"
                                    {...params}
                                    value={search}
                                    onChange={(event) =>
                                        setSearch(event.target.value)
                                    }
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({
                                        index,
                                    });
                                    return (
                                        <Chip
                                            variant="outlined"
                                            label={`${option.subKategori.nama} - ${option.nama}`}
                                            key={key}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                        />
                    </StepContent>
                </Step>
                <Step active>
                    <StepLabel>Pilih Format Katalog</StepLabel>
                    <StepContent>
                        <Typography color={textPrimary.body}>
                            Pilih format katalog untuk diunduh.
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                name="radio-button-format-katalog"
                                value={catalogType}
                                onChange={(event) =>
                                    setCatalogType(event.target.value)
                                }
                            >
                                <FormControlLabel
                                    value="List"
                                    control={<Radio />}
                                    label="List"
                                />
                                <FormControlLabel
                                    value="Menu"
                                    control={<Radio />}
                                    label="Menu"
                                />
                                <FormControlLabel
                                    value="Excel"
                                    control={<Radio />}
                                    label="Excel (.xls)"
                                />
                            </RadioGroup>
                        </FormControl>
                        <Box>
                            {catalogType === "List" ||
                            catalogType === "Excel" ? (
                                <TemplateList width="25%" height="25%" />
                            ) : (
                                <TemplateMenu width="25%" height="25%" />
                            )}
                        </Box>
                        <Stack direction="row" justifyContent="flex-end">
                            <LoadingButton
                                variant="contained"
                                loading={downloadLoading}
                                onClick={download}
                                type="submit"
                                fullWidth={!isDesktopScreen}
                            >
                                Buat Katalog
                            </LoadingButton>
                        </Stack>
                    </StepContent>
                </Step>
            </Stepper>
        </>
    );
}
