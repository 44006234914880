import { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import Spinner from "../Spinner/Spinner";
import useUser from "../../services/queries/useUser";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    AddSubCategory,
    ContentGetKategoriResponse,
    DefaultResponse,
    ErrorFieldResponse,
    SubCategoriesParams,
    SubCategory,
} from "../../constants/types";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import { Add, Close, Search } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import useSubCategories from "../../services/queries/useSubCategories";
import ModalEditSubKategori from "../ModalEditSubKategori/ModalEditSubKategori";
import useKategori from "../../services/queries/useKategori";
import ModalDeleteSubKategori from "../ModalDeleteSubKategori/ModalDeleteSubKategori";
import { textPrimary } from "../../constants/colors";

const schema = yup
    .object({
        subKategoriId: yup.string().required("Kolom wajib diisi"),
        namaKategori: yup.string().required("Kolom wajib diisi"),
    })
    .required();

export default function AturSubKategori() {
    const { ukmIdUser } = useAuth();
    const { status: statusUser } = useUser();

    const category = useKategori({
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 100,
        page: 1,
        search: "",
    });

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        search: "",
    };
    const [subCategoriesParams, setSubCategoriesParams] =
        useState<SubCategoriesParams>(initialParams);
    const { data, refetch, isLoading } = useSubCategories(subCategoriesParams);

    const [addSubCategoryOpen, setAddSubCategoryOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] =
        useState<ContentGetKategoriResponse | null>(null);

    const [selectedSubCategory, setSelectedSubCategory] = useState<
        SubCategory | undefined
    >(undefined);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [search, setSearch] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama Sub Kategori",
            flex: 1,
            minWidth: 170,
        },
        {
            field: "namaSubKategori",
            headerName: "Nama Kategori",
            flex: 1,
            minWidth: 170,
            renderCell: (params) => params.row.subKategori.nama,
        },
        {
            field: "jumlahProduk",
            headerName: "Jumlah Produk",
            flex: 1,
            minWidth: 170,
            renderCell: (params) =>
                `${params.row.produkTerhubung.length} produk`,
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 130,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setSelectedSubCategory(params.row);
                                setOpenEditDialog(true);
                            }}
                            size="small"
                            color="buttonyellow"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <EditIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setSelectedSubCategory(params.row);
                                setOpenDeleteDialog(true);
                            }}
                            size="small"
                            color="buttonred"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const handleOnPagination = (page: number) => {
        setSubCategoriesParams((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setSubCategoriesParams((prev) => ({ ...prev, size }));
    };

    function handleSearch() {
        setSubCategoriesParams((prev) => ({
            ...prev,
            search,
            page: 1,
        }));
    }

    function closeAddDialog() {
        setAddSubCategoryOpen(false);
        setSelectedCategory(null);
        reset();
    }

    function closeEditDialog() {
        setOpenEditDialog(false);
        setSelectedSubCategory(undefined);
    }

    function closeDeleteDialog() {
        setOpenDeleteDialog(false);
        setSelectedSubCategory(undefined);
    }

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaSubKategori: "",
        }),
        [ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
        setValue,
    } = useForm<AddSubCategory>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const queryClient = useQueryClient();

    const onSubmit = async (values: AddSubCategory) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<DefaultResponse>(
                `/api/kategori`,
                values,
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Sub kategori berhasil ditambahkan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                refetch();
            }
            setIsButtonLoading(false);
            setAddSubCategoryOpen(false);
            queryClient.invalidateQueries("sub-categories");
            reset(initialValues);
        } catch (err) {
            if (defaultAxios.isAxiosError(err)) {
                const serverError = err as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    console.log(serverError.response?.data?.errors);
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as any, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
                setIsButtonLoading(false);
            }
            handleErrorResponse(err);
            setIsButtonLoading(false);
        }
    };

    const handleErrorResponse = useCallback((error) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            console.log(serverError.response);
            if (serverError && serverError.response) {
                console.log(`serverError`, serverError);
                if (serverError.response.data.data.errors) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            } else {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `Terjadi kesalahan! Silahkan coba lagi.`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        }
    }, []);

    useEffect(() => {
        if (ukmIdUser) {
            setSubCategoriesParams((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    if (statusUser === "loading") {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    color={textPrimary.title}
                    fontSize={32}
                    fontWeight="bold"
                    py={2}
                >
                    Atur Sub Kategori
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setAddSubCategoryOpen(true)}
                >
                    Tambah Sub Kategori
                </Button>
            </Stack>
            <TextField
                placeholder="Cari sub kategori"
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(event.target.value);
                }}
                onKeyDown={(e) => {
                    const element = e.target as HTMLInputElement;
                    if (e.key === "Enter") {
                        setSubCategoriesParams((prev) => ({
                            ...prev,
                            search: element.value,
                            page: 1,
                        }));
                    }
                }}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        backgroundColor: "white",
                    },
                }}
            />
            <Box
                sx={{
                    width: "100%",
                    marginTop: 2,
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    autoHeight
                    paginationMode="server"
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExportIcon />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    rows={data?.content ?? []}
                    columns={columns}
                    disableColumnMenu
                    disableSelectionOnClick
                    rowsPerPageOptions={[10]}
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={data?.totalElements ?? 0}
                    page={subCategoriesParams.page - 1}
                    pageSize={subCategoriesParams.size}
                    density="standard"
                    loading={isLoading}
                />
            </Box>

            <Dialog
                open={addSubCategoryOpen}
                fullWidth
                maxWidth="md"
                onClose={closeAddDialog}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                    }}
                >
                    <Typography
                        color={textPrimary.title}
                        fontSize={32}
                        fontWeight={700}
                    >
                        Tambah Sub Kategori
                    </Typography>
                    <IconButton onClick={() => setAddSubCategoryOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ display: "grid", gap: "1rem" }}
                    >
                        <div>
                            <Typography
                                color={textPrimary.body}
                                fontWeight={500}
                                mb={1}
                            >
                                Pilih Kategori
                            </Typography>
                            <Autocomplete
                                id="subKategoriId"
                                fullWidth
                                loading={category.isLoading}
                                disablePortal
                                options={category.data?.content ?? []}
                                getOptionLabel={(option) => option.nama}
                                isOptionEqualToValue={(option, value) =>
                                    String(option.id) === String(value.id)
                                }
                                value={selectedCategory}
                                onChange={(_, val) => {
                                    setSelectedCategory(val);
                                    // @ts-expect-error Form value can be null
                                    setValue("subKategoriId", val?.id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder="Pilih Kategori"
                                        variant="outlined"
                                        error={Boolean(errors.subKategoriId)}
                                        helperText={
                                            errors.subKategoriId
                                                ? errors.subKategoriId.message
                                                : ""
                                        }
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Typography
                                color={textPrimary.body}
                                fontWeight={500}
                                mb={1}
                            >
                                Nama Sub Kategori
                            </Typography>
                            <Controller
                                name="namaKategori"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="namaKategori"
                                        placeholder="Masukkan nama sub kategori yang hendak ditambahkan"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaKategori)}
                                        helperText={
                                            errors.namaKategori
                                                ? errors.namaKategori.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: "Nama Kategori required",
                                }}
                            />
                        </div>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            gap={1}
                            mt={2}
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={closeAddDialog}
                            >
                                Kembali
                            </Button>
                            <LoadingButton
                                loading={isButtonLoading}
                                variant="contained"
                                startIcon={<AddIcon />}
                                type="submit"
                            >
                                Tambahkan
                            </LoadingButton>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
            <ModalEditSubKategori
                value={selectedSubCategory}
                open={openEditDialog}
                onClose={closeEditDialog}
            />
            <ModalDeleteSubKategori
                value={selectedSubCategory}
                open={openDeleteDialog}
                onClose={closeDeleteDialog}
            />
        </>
    );
}
