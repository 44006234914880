import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import defaultAxios, { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { ChangePasswordResponse, PasswordValues } from "../../constants/types";
import axios from "../../services/axios";
import useNoWaAdmin from "../../services/queries/useNoWaAdmin";
import LupaPasswordDialog from "../LupaPasswordDialog/LupaPasswordDialog";
import { textPrimary } from "../../constants/colors";

interface ICardChangePasswordProps {}

const schema = yup
    .object({
        passwordLama: yup.string().required("Kolom wajib diisi"),
        passwordBaru: yup
            .string()
            .required("Kolom wajib diisi")
            .min(8, "Kata sandi minimal 8 karakter"),
        passwordBaruUlang: yup
            .string()
            .required("Kolom wajib diisi")
            .oneOf([yup.ref("passwordBaru")], "Kata sandi baru tidak sama"),
    })
    .required();

const CardChangePassword = (props: ICardChangePasswordProps) => {
    const { data: noWaAdmin } = useNoWaAdmin();
    const [showPasswordLama, setShowPasswordLama] = useState(false);
    const [showPasswordBaru, setShowPasswordBaru] = useState(false);
    const [showPasswordBaruUlang, setShowPasswordBaruUlang] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const initialValues = useMemo(
        () => ({
            passwordLama: "",
            passwordBaru: "",
            passwordBaruUlang: "",
        }),
        [],
    );

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
    } = useForm<PasswordValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: PasswordValues) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<ChangePasswordResponse>(
                `/api/password`,
                {
                    passwordLama: values.passwordLama,
                    passwordBaru: values.passwordBaru,
                },
            );
            if (data.code === 200) {
                setIsButtonLoading(false);
                Swal.fire({
                    title: "Kata sandi berhasil diubah",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            reset(initialValues);
        } catch (error) {
            setIsButtonLoading(false);
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ChangePasswordResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.code === 400) {
                        const errorMessage = fieldError.message;
                        setError("passwordLama", {
                            type: "manual",
                            message: errorMessage,
                        });
                    }
                }
            }
        }
    };

    return (
        <>
            <Typography component="h1" fontSize={32} fontWeight={700}>
                Ubah Kata Sandi
            </Typography>
            <Typography>
                Lakukan perubahan kata sandi secara berkala untuk menjaga
                keamanan akun anda.
            </Typography>
            <Stack spacing={0.5} marginTop={4}>
                <Stack spacing={1}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Kata Sandi Lama
                    </Typography>
                    <Controller
                        name="passwordLama"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="passwordLama"
                                fullWidth
                                placeholder="Masukkan kata sandi lama"
                                type={showPasswordLama ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPasswordLama(
                                                        (prev) => !prev,
                                                    );
                                                }}
                                                edge="end"
                                            >
                                                {showPasswordLama ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(errors.passwordLama)}
                                helperText={
                                    errors.passwordLama
                                        ? errors.passwordLama.message
                                        : " "
                                }
                                {...field}
                            />
                        )}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Kata Sandi Baru
                    </Typography>
                    <Controller
                        name="passwordBaru"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="passwordBaru"
                                fullWidth
                                placeholder="Minimal 8 karakter"
                                type={showPasswordBaru ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPasswordBaru(
                                                        (prev) => !prev,
                                                    );
                                                }}
                                                edge="end"
                                            >
                                                {showPasswordBaru ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(errors.passwordBaru)}
                                helperText={
                                    errors.passwordBaru
                                        ? errors.passwordBaru.message
                                        : " "
                                }
                                {...field}
                            />
                        )}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Ulangi Kata Sandi Baru
                    </Typography>
                    <Controller
                        name="passwordBaruUlang"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="passwordBaruUlang"
                                fullWidth
                                placeholder="Minimal 8 karakter"
                                type={
                                    showPasswordBaruUlang ? "text" : "password"
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPasswordBaruUlang(
                                                        (prev) => !prev,
                                                    );
                                                }}
                                                edge="end"
                                            >
                                                {showPasswordBaruUlang ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(errors.passwordBaruUlang)}
                                helperText={
                                    errors.passwordBaruUlang
                                        ? errors.passwordBaruUlang.message
                                        : " "
                                }
                                {...field}
                            />
                        )}
                    />
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                marginTop={2}
            >
                <Button onClick={() => setOpenDialog(true)}>
                    Lupa Kata Sandi
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                >
                    Simpan Perubahan
                </LoadingButton>
            </Stack>
            <LupaPasswordDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />
        </>
    );
};

export default CardChangePassword;
