import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { SubCategory } from "../../constants/types";
import { textPrimary } from "../../constants/colors";

type Props = {
    value?: SubCategory;
    open: boolean;
    onClose: () => void;
};

const ModalDeleteSubKategori = ({ value, open, onClose }: Props) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const queryClient = useQueryClient();

    const handleDelete = async (id: string | number | undefined) => {
        if (!id) return;
        setIsButtonLoading(true);
        try {
            const { data } = await axios.delete(`/api/kategori/${id}`);
            if (data.code === 200) {
                Swal.fire({
                    title: "Sub kategori " + value?.nama + " berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            queryClient.invalidateQueries("sub-categories");
            onClose();
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback((error) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError.response) {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `${serverError.response.data.message}`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
            } else {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `Telah Terjadi Kesalahan`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        }
    }, []);

    return (
        <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <Typography
                    color={textPrimary.title}
                    fontSize={32}
                    fontWeight={700}
                >
                    Konfirmasi Hapus
                </Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Anda yakin ingin menghapus <strong>{value?.nama}</strong>?
                    Data yang sudah dihapus tidak dapat dikembalikan. Anda perlu
                    menghapus semua produk yang terdapat pada sub kategori untuk
                    menghapus.
                </DialogContentText>
                <Stack direction="row" justifyContent="flex-end" gap={1} mt={2}>
                    <Button type="button" variant="outlined" onClick={onClose}>
                        Kembali
                    </Button>
                    <LoadingButton
                        loading={isButtonLoading}
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(value?.id)}
                    >
                        Konfirmasi Hapus
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDeleteSubKategori;
