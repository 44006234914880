import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import defaultAxios, { AxiosError } from "axios";
import { useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import LogoUlo from "../../components/LogoUlo/LogoUlo";
import ThemeDefaultComponent from "../../components/ThemeDefaultComponent/ThemeDefaultComponent";
import { captchaSiteKey2 } from "../../constants/common";
import { LupaPasswordResponse } from "../../constants/types";
import axios from "../../services/axios";

interface LupaPasswordDialogProps {
    open: boolean;
    onClose: () => void;
}

interface LupaPasswordDialogInput {
    email: string;
}

const schema = yup
    .object({
        email: yup
            .string()
            .email("Format email salah")
            .required("Kolom wajib diisi"),
    })
    .required();

const LupaPasswordDialog = (props: LupaPasswordDialogProps) => {
    const theme = useTheme();
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [token, setToken] = useState<string | null>();
    const [isHuman, setIsHuman] = useState(false);

    const history = useHistory();

    const initialValues = useMemo(
        () => ({
            email: "",
        }),
        [],
    );

    const {
        handleSubmit,
        setError,
        control,
        formState: { errors },
    } = useForm<LupaPasswordDialogInput>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const handleErrorReponse = (error: any) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError?.response) {
                const fieldError = serverError?.response?.data;
                if (fieldError?.errors) {
                    Object.keys(fieldError.errors).forEach((key) => {
                        const errorMessage = fieldError.errors[key];
                        setError(key as keyof typeof initialValues, {
                            type: "manual",
                            message: errorMessage[0],
                        });
                    });
                } else {
                    Swal.fire({
                        title: `${fieldError.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        }
    };

    const onSubmit = async (values: LupaPasswordDialogInput) => {
        console.log("Email: ", values);
        setIsButtonLoading(true);
        if (isHuman && token) {
            try {
                const formData = new FormData();
                formData.append("email", values.email);
                const { data } = await axios.post<LupaPasswordResponse>(
                    `/api/reqgantipass`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    setIsButtonLoading(false);
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    history.push(`/`);
                } else {
                    setIsButtonLoading(false);
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorReponse(error);
                console.error(error);
            }
        } else {
            setIsButtonLoading(false);
            Swal.fire({
                title: "Terjadi Kesalahan!",
                text: `Tolong centang box reCAPTCHA`,
                icon: "error",
                confirmButtonColor: "#45A779",
                customClass: {
                    container: "my-swal",
                },
            });
        }
    };

    return (
        <ThemeDefaultComponent>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="md"
                fullWidth
            >
                <IconButton sx={{ position: "absolute", top: 4, right: 4 }}>
                    <Close />
                </IconButton>
                <DialogContent>
                    <Stack spacing={3} mt={4} alignItems="center">
                        <Link
                            href="https://ukmlokal.com/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <LogoUlo width="100px" />
                        </Link>
                        <Box>
                            <Typography
                                component="div"
                                textAlign="center"
                                variant="h6"
                                fontWeight="bold"
                            >
                                Lupa{" "}
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: "#20965F" }}
                                    display="inline"
                                >
                                    Kata Sandi?
                                </Typography>
                            </Typography>
                            <Typography
                                marginTop={1}
                                variant="body2"
                                textAlign="center"
                                width="300px"
                            >
                                Masukkan email anda untuk mendapatkan tautan
                                atur ulang kata sandi.
                            </Typography>
                        </Box>
                        <form
                            style={{
                                width: "100%",
                                maxWidth: "600px",
                                minWidth: "300px",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Stack>
                                <Typography fontWeight={500}>Email</Typography>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.email)}
                                        >
                                            <OutlinedInput
                                                id="email"
                                                type="email"
                                                autoComplete="email"
                                                autoFocus={isLaptopScreen}
                                                placeholder="Contoh: ulo@gmail.com"
                                                {...field}
                                            />
                                            <FormHelperText>
                                                {errors.email
                                                    ? errors.email.message
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={{ required: "Email required" }}
                                />
                            </Stack>
                            <Box
                                marginTop={1}
                                display="flex"
                                justifyContent="center"
                            >
                                <ReCAPTCHA
                                    sitekey={captchaSiteKey2}
                                    onChange={(value) => {
                                        setToken(value);
                                        setIsHuman(true);
                                    }}
                                    onExpired={() => {
                                        setToken(null);
                                        setIsHuman(false);
                                    }}
                                    hl="id"
                                />
                            </Box>
                            <Box textAlign="center">
                                <LoadingButton
                                    loading={isButtonLoading}
                                    loadingPosition="center"
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginTop: 3 }}
                                >
                                    Kirim ke Email
                                </LoadingButton>
                            </Box>
                        </form>
                        <Box
                            display="flex"
                            justifyContent="center"
                            marginTop={3}
                            sx={{ width: "100%" }}
                        >
                            <Button
                                onClick={props.onClose}
                                sx={{
                                    fontWeight: 400,
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                Kembali ke Pengaturan
                            </Button>
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
        </ThemeDefaultComponent>
    );
};

export default LupaPasswordDialog;
