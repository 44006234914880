import { Button, Typography } from "@mui/material";
import React, { ReactNode } from "react";
// import "./ButtonKalkulator.tsx";

interface IButtonKalkulatorProps {
    children: ReactNode;
    buttonVariant: "text" | "outlined" | "contained" | undefined;
    onClick: () => void;
    disabled?: boolean;
}

const ButtonKalkulator = ({
    children,
    onClick,
    buttonVariant,
    disabled,
}: IButtonKalkulatorProps) => {
    return (
        <Button
            className="calcButton"
            disabled={disabled}
            variant={buttonVariant}
            onClick={onClick}
            sx={{
                backgroundColor: "primary",
            }}
        >
            <Typography
                className="textButton"
                sx={{
                    fontSize: { xs: "subtitle1.fontSize", sm: "h6.fontSize" },
                }}
            >
                {children}
            </Typography>
        </Button>
    );
};

export default ButtonKalkulator;
