import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    Input,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import defaultAxios, { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
    EditUserResponse,
    ErrorFieldResponse,
    UserValues,
} from "../../constants/types";
import axios from "../../services/axios";
import useUser from "../../services/queries/useUser";
import avatarAlt from "../../utils/avatarAlt";
import { textPrimary } from "../../constants/colors";

interface ICardInfoUserProps {}

const schema = yup.object({
    namaLengkap: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    username: yup
        .string()
        .required("Kolom wajib diisi")
        .min(6, "Username terlalu pendek")
        .max(50, "Username terlalu panjang"),
    email: yup
        .string()
        .email("Format email salah")
        .required("Kolom wajib diisi")
        .max(50, "Email terlalu panjang"),
    gambar: yup.mixed(),
    // .nullable()
    // .test("fileType", "Gambar harus berformat jpg/jpeg/png", (value) =>
    //     ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    // ),
    noTelp: yup
        .string()
        .required("Kolom wajib diisi")
        .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
        .min(9, "No. Telepon terlalu pendek")
        .max(13, "No. Telepon terlalu panjang"),
});

const CardInfoUser = (props: ICardInfoUserProps) => {
    const { data: user, refetch } = useUser();
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [image, setImage] = useState<string>(String(user?.gambar));

    const initialValues = useMemo(
        () => ({
            namaLengkap: user?.namaLengkap,
            username: user?.username,
            email: user?.email,
            gambar: user?.gambar,
            noTelp: user?.noTelp?.slice(2) ?? "",
        }),
        [
            user?.email,
            user?.gambar,
            user?.namaLengkap,
            user?.noTelp,
            user?.username,
        ],
    );

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useForm<UserValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: UserValues) => {
        setIsButtonLoading(true);
        console.log(values);
        const formattedNoTelp = "62" + String(values.noTelp);
        try {
            const formData = new FormData();
            formData.append("namaLengkap", values.namaLengkap);
            formData.append("username", values.username);
            formData.append("email", values.email);
            formData.append("noTelp", formattedNoTelp);
            if (values.gambar && typeof values.gambar !== "string") {
                formData.append("gambar", values.gambar as any);
            }
            const { data } = await axios.post<EditUserResponse>(
                `/api/users`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Pengaturan akun berhasil diubah",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(true);
            reset(initialValues);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (isSuccessSubmit === true) {
            refetch();
        }
        setIsSuccessSubmit(false);
        console.log(isSuccessSubmit);
    }, [isSuccessSubmit, refetch]);

    useEffect(() => {
        if (user) {
            reset(initialValues);
        }
    }, [initialValues, reset, user]);

    useEffect(() => {
        console.log("Image:", image);
        getValues();
    }, [getValues, image]);

    return (
        <Box>
            <Stack spacing={1} marginY={3} alignItems="center">
                <Controller
                    name="gambar"
                    control={control}
                    render={({ field }) => (
                        <React.Fragment>
                            <Avatar
                                alt={user?.namaLengkap}
                                src={
                                    typeof field.value !== "string"
                                        ? image
                                        : typeof field.value === "string"
                                        ? field.value
                                        : user?.namaLengkap
                                }
                                sx={{
                                    bgcolor: "primary.main",
                                    width: 100,
                                    height: 100,
                                }}
                            >
                                {avatarAlt(String(user?.namaLengkap))}
                            </Avatar>
                            <Box sx={{ textAlign: "center" }}>
                                <label htmlFor="outlined-button-file">
                                    <Input
                                        inputProps={{
                                            accept: "image/*",
                                            style: {
                                                display: "none",
                                            },
                                        }}
                                        id="outlined-button-file"
                                        type="file"
                                        onChange={(event: any) => {
                                            if (
                                                event.target.files[0] &&
                                                event.target.files[0].size <
                                                    2097152
                                            ) {
                                                console.log(
                                                    event.target.files[0],
                                                );
                                                setImage(
                                                    URL.createObjectURL(
                                                        event.target.files[0],
                                                    ),
                                                );
                                                setValue(
                                                    "gambar",
                                                    event.target.files[0],
                                                );
                                                clearErrors("gambar");
                                            } else {
                                                event.target.value = "";
                                                setError("gambar", {
                                                    type: "manual",
                                                    message:
                                                        "Ukuran gambar harus kurang dari 2MB",
                                                });
                                            }
                                        }}
                                    />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        component="span"
                                        sx={{ mt: 2 }}
                                    >
                                        Ubah Foto
                                    </Button>
                                </label>
                                <Box mt={1}>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        *Resolusi optimal: 300 x 300
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        *Ukuran maksimal: 2MB
                                    </Typography>
                                    {errors.gambar ? (
                                        <Typography
                                            variant="caption"
                                            color="#FF0000"
                                        >
                                            {errors.gambar.message}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="caption"
                                            color="#FFF"
                                        >
                                            null
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </React.Fragment>
                    )}
                />
            </Stack>
            <Stack spacing={0.5} marginTop={1}>
                <Stack spacing={1}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Nama Lengkap
                    </Typography>
                    <Controller
                        name="namaLengkap"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="namaLengkap"
                                fullWidth
                                placeholder="Contoh: Budi Saputra"
                                error={Boolean(errors.namaLengkap)}
                                helperText={
                                    errors.namaLengkap
                                        ? errors.namaLengkap.message
                                        : " "
                                }
                                {...field}
                            />
                        )}
                    />
                </Stack>
                <Typography color={textPrimary.body} fontWeight={500}>
                    Username
                </Typography>
                <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            id="username"
                            fullWidth
                            placeholder="Contoh: budi93"
                            inputProps={{
                                style: {
                                    textTransform: "lowercase",
                                },
                            }}
                            error={Boolean(errors.username)}
                            helperText={
                                errors.username ? errors.username.message : " "
                            }
                            {...field}
                        />
                    )}
                />
                <Stack spacing={1}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Email
                    </Typography>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="email"
                                fullWidth
                                type="email"
                                placeholder="Contoh: budi93@gmail.com"
                                error={Boolean(errors.email)}
                                helperText={
                                    errors.email ? errors.email.message : " "
                                }
                                {...field}
                            />
                        )}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Nomor Telepon
                    </Typography>
                    <Controller
                        name="noTelp"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="noTelp"
                                fullWidth
                                placeholder="831xxxxxx"
                                error={Boolean(errors.noTelp)}
                                helperText={
                                    errors.noTelp ? errors.noTelp.message : " "
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            sx={{
                                                color: "#000",
                                            }}
                                        >
                                            <Typography>+62</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                {...field}
                            />
                        )}
                    />
                </Stack>
            </Stack>
            <Box textAlign="right">
                <LoadingButton
                    disabled={!!errors.gambar}
                    loading={isButtonLoading}
                    loadingPosition={"center"}
                    variant="contained"
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    sx={{ marginTop: 2 }}
                >
                    Simpan Perubahan
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default CardInfoUser;
