import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    TextField,
    Button,
    Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../../services/axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    ContentGetKategoriResponse,
    EditSubCategory,
    ErrorFieldResponse,
    SubCategory,
} from "../../constants/types";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import useKategori from "../../services/queries/useKategori";
import { useAuth } from "../../context/authContext";
import { textPrimary } from "../../constants/colors";

type Props = {
    value?: SubCategory;
    open: boolean;
    onClose: () => void;
};

const schema = yup
    .object({
        subKategoriId: yup.string().required("Kolom wajib diisi"),
        namaKategori: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const ModalEditSubKategori = ({ value, open, onClose }: Props) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { ukmIdUser } = useAuth();
    const queryClient = useQueryClient();

    const category = useKategori({
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 100,
        page: 1,
    });

    const [selectedCategory, setSelectedCategory] =
        useState<ContentGetKategoriResponse | null>(null);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
        getValues,
        setError,
        setValue,
    } = useForm<EditSubCategory>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (value) {
            setSelectedCategory(
                category.data?.content.find(
                    (item) => item.id === value.subKategori.id,
                ) ?? null,
            );
            reset({
                namaKategori: value.nama,
                subKategoriId: value.subKategori.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function handleClose() {
        onClose();
        reset();
    }

    const onSubmit = async (values: EditSubCategory) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.put(
                `/api/kategori/${value?.id}`,
                values,
            );
            if (data.code === 200) {
                Swal.fire({
                    title:
                        "Sub kategori berhasil diubah menjadi " +
                        getValues("namaKategori"),
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            queryClient.invalidateQueries("sub-categories");
            handleClose();
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof EditSubCategory, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <Typography
                    color={textPrimary.title}
                    fontSize={32}
                    fontWeight={700}
                >
                    Ubah Sub Kategori
                </Typography>
                <IconButton
                    onClick={() => {
                        onClose();
                        reset();
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "grid", gap: "1rem" }}
                >
                    <div>
                        <Typography
                            color={textPrimary.body}
                            fontWeight={500}
                            mb={1}
                        >
                            Pilih Kategori
                        </Typography>
                        <Autocomplete
                            id="subKategoriId"
                            fullWidth
                            loading={category.isLoading}
                            disablePortal
                            options={category.data?.content ?? []}
                            getOptionLabel={(option) => option.nama}
                            isOptionEqualToValue={(option, value) =>
                                String(option.id) === String(value.id)
                            }
                            value={selectedCategory}
                            onChange={(_, val) => {
                                setSelectedCategory(val);
                                // @ts-expect-error Form value can be null
                                setValue("subKategoriId", val?.id);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Pilih Kategori"
                                    variant="outlined"
                                    error={Boolean(errors.subKategoriId)}
                                    helperText={
                                        errors.subKategoriId
                                            ? errors.subKategoriId.message
                                            : ""
                                    }
                                    {...params}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Typography
                            color={textPrimary.body}
                            fontWeight={500}
                            mb={1}
                        >
                            Nama Sub Kategori
                        </Typography>
                        <Controller
                            name="namaKategori"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    id="namaKategori"
                                    placeholder="Masukkan nama sub kategori yang hendak ditambahkan"
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(errors.namaKategori)}
                                    helperText={
                                        errors.namaKategori
                                            ? errors.namaKategori.message
                                            : ""
                                    }
                                    {...field}
                                />
                            )}
                            rules={{
                                required: "Nama Kategori required",
                            }}
                        />
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap={1}
                        mt={2}
                    >
                        <Button
                            type="button"
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Kembali
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            variant="contained"
                            type="submit"
                        >
                            Simpan Perubahan
                        </LoadingButton>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEditSubKategori;
