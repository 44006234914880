import { useMutation, useQueryClient } from "react-query";
import axios from "../axios";

export default function useVoidPiutang() {
    const queryClient = useQueryClient();

    return useMutation(
        async (id: number) => {
            const res = await axios.delete(`/api/pembayaran-transaksis/${id}`);
            return res.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("riwayat-piutang");
            },
        },
    );
}
