import {
    Typography,
    Grid,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Box,
    Button,
    Stack,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { SelectedProductMutasi } from "../../constants/types";
import DetailItemMutasiStep from "../ModalMutasiStok/DetailItemMutasiStep/DetailItemMutasiStep";
import PilihItemMutasiStep from "../ModalMutasiStok/PilihItemMutasiStep/PilihItemMutasiStep";
import { textPrimary } from "../../constants/colors";

export default function MutasiStok() {
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedProductMutasi[]>([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setProducts([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedProductMutasi[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    const steps = [
        {
            label: "Pilih produk",
            description: `Anda dapat memilih satu atau lebih produk yang hendak dimutasi.`,
        },
        {
            label: "Masukkan mutasi",
            description:
                "Atur mutasi masuk atau keluar untuk setiap stok barang anda.",
        },
    ];

    useEffect(() => {
        resetBundlingSteps();
    }, []);

    return (
        <>
            <Typography
                color={textPrimary.title}
                fontSize={32}
                fontWeight="bold"
                py={2}
            >
                Mutasi Stok
            </Typography>
            <Grid>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={String(index + 1)}>
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>
                                <Typography color="GrayText">
                                    {step.description}
                                </Typography>
                                <Box my={4}>
                                    {index === 0 && (
                                        <Fragment>
                                            <PilihItemMutasiStep
                                                addProducts={addProducts}
                                            />
                                            <Stack
                                                mt={4}
                                                direction="row"
                                                justifyContent="flex-end"
                                                gap={2}
                                            >
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    variant="outlined"
                                                >
                                                    Kembali
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    disabled={
                                                        activeStep === 0 &&
                                                        products.length === 0
                                                    }
                                                    onClick={handleNext}
                                                >
                                                    Lanjutkan
                                                </Button>
                                            </Stack>
                                        </Fragment>
                                    )}
                                    {index === 1 && (
                                        <DetailItemMutasiStep
                                            products={products}
                                            onBack={handleBack}
                                        />
                                    )}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
        </>
    );
}
