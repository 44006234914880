import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Autocomplete,
    Box,
    Checkbox,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    SelectedProductBundling,
    ParamsGetItem,
    ParamsGetKategori,
    IOptions,
    SubCategoriesParams,
} from "../../../constants/types";
import useItem from "../../../services/queries/useItem";
import useKategori from "../../../services/queries/useKategori";
import useUser from "../../../services/queries/useUser";
import toRupiah from "../../../utils/toRupiah";
import Spinner from "../../Spinner/Spinner";
import SearchIcon from "@mui/icons-material/Search";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useAuth } from "../../../context/authContext";
import "./PilihItemStep.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useStokModal } from "../../../context/stokModalContext";
import NoRowsImage from "../../NoRowsImage/NoRowsImage";
import LoadingTabel from "../../LoadingTabel/LoadingTabel";
import truncate from "../../../utils/truncate";
import { useTheme } from "@mui/material/styles";
import useSubKategori from "../../../services/queries/useSubKategori";

interface IPilihItemStepProps {
    addProducts: (item: SelectedProductBundling[]) => void;
}

const PilihItemStep = ({ addProducts }: IPilihItemStepProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { data: user, status: statusUser } = useUser();
    const { ukmIdUser } = useAuth();
    const { dataStok } = useStokModal();

    const initialParamsStok: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        kategori: "",
        isShow: 0,
        size: 10,
        page: 1,
    };

    const initialParamsKategori: ParamsGetKategori = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 500,
        page: 1,
    };

    const initialParamsSubKategori: SubCategoriesParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 500,
        page: 1,
    };

    const [paramsStok, setParamsStok] = useState(initialParamsStok);
    const [paramsKategori, setParamsKategori] = useState(initialParamsKategori);
    const [paramsSubKategori, setParamsSubKategori] = useState(
        initialParamsSubKategori,
    );
    const [search, setSearch] = useState<string>("");
    const [selectedKategori, setSelectedKategori] = useState<IOptions | null>(
        null,
    );
    const [selectedSubKategori, setSelectedSubKategori] =
        useState<IOptions | null>(null);

    const { data: stok, isLoading: isLoadingStok } = useItem(paramsStok);
    const {
        data: kategori,
        status: statusKategori,
        isLoading: isLoadingKategori,
    } = useKategori(paramsKategori);
    const {
        data: subKategori,
        status: statusSubKategori,
        isLoading: isLoadingSubKategori,
    } = useSubKategori(paramsSubKategori);

    // const filteredRows = stok?.content.filter((value) => value.stok > 0);
    const [selectedProducts, setSelectedProducts] = useState<
        SelectedProductBundling[]
    >([]);

    const columns: GridColDef[] = [
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                <Checkbox
                    onChange={handleClikAllProduct}
                    checked={selectedProducts.length === stokAllProduct?.length}
                />
            ),
            renderCell: (params) => {
                return (
                    <IconButton
                        disabled={
                            (params.row.stok <= 0 &&
                                params.row.stok !== null) ||
                            params.row.isBundling > 0
                        }
                        onClick={() => {
                            handleSelectedProducts(
                                params.row.id,
                                params.row.namaBarang,
                                params.row.harga,
                                params.row.stok,
                            );
                        }}
                        sx={{
                            minWidth: "unset",
                            padding: "6px",
                            width: "fit-content",
                        }}
                        size="small"
                        color="primary"
                    >
                        {isSelected(params.row.id) ? (
                            <CheckBoxIcon />
                        ) : (
                            <CheckBoxOutlineBlankIcon />
                        )}
                    </IconButton>
                );
            },
        },
        {
            field: "namaBarang",
            headerName: "Nama Barang",
            width: 300,
            flex: 2,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">
                        {truncate(params.value, 15)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "sku",
            headerName: "SKU",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "kategori",
            headerName: "Sub Kategori",
            flex: 1,
            minWidth: 125,
            renderCell: (params) => (
                <Tooltip title={params.value.nama}>
                    <span className="table-cell-trucate">
                        {params.value.nama}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "stok",
            headerName: "Stok",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Tooltip
                    title={params.value === null ? "\u221e" : params.value}
                >
                    <span className="table-cell-trucate">
                        {params.value === null ? "\u221e" : params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "harga",
            headerName: "Harga",
            headerAlign: "center",
            align: "right",
            flex: 1,
            minWidth: 125,
            valueGetter: (params) => params.value ?? 0,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {toRupiah(params.value)}
                        </span>
                    </Tooltip>
                );
            },
        },
    ];

    const stokAllProduct = useMemo(() => {
        if (stok && stok.content.length > 0) {
            return stok.content
                ?.filter((item) => item.isBundling === 0)
                .map((list) => ({
                    id: list.id,
                    nama: list.namaBarang,
                    harga: list.harga,
                    stok: list.stok,
                }));
        }
        // setSelectedProducts([]);
    }, [stok]);

    const handleClikAllProduct = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                console.log(event.target.checked);
                setSelectedProducts(stokAllProduct ?? []);
                return;
            }
            return setSelectedProducts([]);
        },
        [stokAllProduct],
    );

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsStok((prev) => ({
            ...prev,
            search: search,
        }));
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                setParamsStok((prev) => ({
                    ...prev,
                    search: search,
                })),
            1000,
        );
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        if (user) {
            setParamsStok((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));

            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser, user]);

    const handleOnPagination = (page: number) => {
        setParamsStok((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsStok((prev) => ({ ...prev, size }));
    };

    const isSelected = (id: number) => {
        const filteredProduct = selectedProducts.filter(
            (li) => li.id === id,
        ).length;
        if (filteredProduct > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSelectedProducts = (
        id: number,
        nama: string,
        harga: number,
        stok: number,
    ) => {
        if (isSelected(id)) {
            const newList = selectedProducts.filter((li) => li.id !== id);
            setSelectedProducts(newList);
        } else {
            setSelectedProducts((prev) => [
                ...prev,
                {
                    id,
                    nama,
                    harga,
                    stok,
                },
            ]);
        }
    };

    useEffect(() => {
        addProducts(selectedProducts ?? []);
    }, [addProducts, selectedProducts]);

    const isLoading =
        statusUser === "loading" ||
        statusKategori === "loading" ||
        statusSubKategori === "loading";

    if (isLoading) {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <Box className="pilih-item">
            {dataStok ? (
                <Box mb={2}>
                    <Typography fontWeight="bold">
                        Barang Sebelumnya:
                    </Typography>
                    <Typography>
                        {dataStok?.detailBundling
                            .map(
                                (li) =>
                                    `${li.namaBarang} (${li.jumlahYangDiambil})`,
                            )
                            .join(", ")}
                    </Typography>
                </Box>
            ) : (
                ""
            )}
            <Box
                display="grid"
                gridTemplateColumns={isTabletScreen ? "1fr" : "1fr 0.5fr 0.5fr"}
                columnGap="1em"
                rowGap={isTabletScreen ? "1em" : undefined}
                mb={2}
            >
                <TextField
                    placeholder="Cari barang..."
                    size="small"
                    value={search}
                    onChange={handleChangeSearch}
                    variant="outlined"
                    fullWidth
                    onKeyPress={(e) => {
                        const element = e.target as HTMLInputElement;
                        if (e.key === "Enter") {
                            setParamsStok((prev) => ({
                                ...prev,
                                search: element.value,
                            }));
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            backgroundColor: "white",
                        },
                    }}
                />
                <Autocomplete
                    disablePortal
                    loading={isLoadingKategori}
                    options={kategori?.content ?? []}
                    getOptionLabel={(option) => option.nama}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    size="small"
                    value={selectedKategori}
                    onChange={(_event, newKategori: IOptions | null) => {
                        setSelectedKategori(newKategori);
                        setParamsSubKategori((prev) => ({
                            ...prev,
                            subKategori: newKategori
                                ? +newKategori.id
                                : undefined,
                        }));
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Kategori" />
                    )}
                />
                <Autocomplete
                    disablePortal
                    loading={isLoadingSubKategori}
                    options={subKategori?.content ?? []}
                    getOptionLabel={(option) => option.nama}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    size="small"
                    value={selectedSubKategori}
                    onChange={(_event, newSubKategori: IOptions | null) => {
                        setSelectedSubKategori(newSubKategori);
                        setParamsStok((prev) => ({
                            ...prev,
                            kategori: newSubKategori
                                ? String(newSubKategori.id)
                                : "",
                        }));
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Sub Kategori" />
                    )}
                />
            </Box>
            <Box
                sx={{
                    height: 460,
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                        // fontSize: "18px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    rows={stok?.content ?? []}
                    // rows={stok?.content ?? []}
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExportIcon />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    columns={columns}
                    // checkboxSelection
                    paginationMode="server"
                    // rowsPerPageOptions={[10, 15, 20]}
                    rowsPerPageOptions={[10]}
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={stok?.totalElements ?? 0}
                    page={paramsStok.page - 1}
                    pageSize={paramsStok.size}
                    disableColumnMenu
                    disableSelectionOnClick
                    density="compact"
                    loading={isLoadingStok}
                    // onSelectionModelChange={handleSelectedProducts}
                    // selectionModel={selectionModel}
                    // isRowSelectable={(params) =>
                    //     params.row.stok > 0 || params.row.stok === null
                    // }
                />
            </Box>
            <Box mt={2}>
                <Typography fontWeight="bold">Barang Terpilih:</Typography>
                <Typography>
                    {selectedProducts.map((li) => li.nama).join(", ")}
                </Typography>
            </Box>
        </Box>
    );
};

export default PilihItemStep;
