import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import toRupiah from "../../utils/toRupiah";
import { format } from "date-fns";
import id from "date-fns/locale/id";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { textPrimary } from "../../constants/colors";

type Props = {
    id: number;
    invoiceNumber: string;
    customerName: string;
    remainingDebt: number;
    dueDate: string | null;
    recentPayment?: string | null;
    selected: boolean;
};

export default function PiutangCard(props: Props) {
    const theme = useTheme();
    const todayDue = useMemo(() => {
        if (!props.dueDate) return false;
        const todayStr = format(new Date(), "yyyy-MM-dd");
        const dueDateStr = format(new Date(props.dueDate), "yyyy-MM-dd");
        return todayStr === dueDateStr;
    }, [props.dueDate]);
    const pastDue = useMemo(() => {
        if (!props.dueDate) return false;
        const todayStr = format(new Date(), "yyyy-MM-dd");
        const dueDateStr = format(new Date(props.dueDate), "yyyy-MM-dd");
        return todayStr > dueDateStr;
    }, [props.dueDate]);

    return (
        <FormControlLabel
            value={props.id}
            control={<Radio checked={props.selected} />}
            label={
                <Stack gap={1}>
                    <Typography color={textPrimary.light} fontSize={12}>
                        {props.invoiceNumber}
                    </Typography>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        {props.customerName}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography color={textPrimary.light} fontSize={12}>
                                Sisa Piutang:
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {toRupiah(props.remainingDebt)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography color={textPrimary.light} fontSize={12}>
                                Jatuh Tempo:
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {props.dueDate
                                    ? format(
                                          new Date(props.dueDate),
                                          "d MMMM y",
                                          {
                                              locale: id,
                                          },
                                      )
                                    : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography color={textPrimary.light} fontSize={12}>
                                Pembayaran Terakhir:
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {props.recentPayment
                                    ? format(
                                          new Date(props.recentPayment),
                                          "d MMMM y",
                                          {
                                              locale: id,
                                          },
                                      )
                                    : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            }
            sx={{
                bgcolor: todayDue
                    ? `${theme.palette.warning.main}19`
                    : pastDue
                    ? `${theme.palette.error.main}19`
                    : "inherit",
                py: 1,
                m: 0,
                borderWidth: props.selected ? 2 : 1,
                borderStyle: "solid",
                borderColor: props.selected
                    ? theme.palette.primary.main
                    : "#a8b4af",
                borderRadius: 2,
                "& > .MuiFormControlLabel-label": {
                    flex: 1,
                },
            }}
        />
    );
}
