import React from "react";
import toRupiah from "../../utils/toRupiah";
import toRibuan from "../../utils/toRibuan";
import StarIcon from "@mui/icons-material/Star";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { useTransaksi } from "../../context/transaksiContext";
import { useDetailBarangModal } from "../../context/detailBarangModalContext";
import {
    Avatar,
    Box,
    Card,
    Button,
    Typography,
    Stack,
    Link,
    useMediaQuery,
    Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./CardBarang.css";
import avatarAlt from "../../utils/avatarAlt";
import { DetailBundling } from "../../constants/types";
import truncate from "../../utils/truncate";
import { textPrimary } from "../../constants/colors";

interface ICardBarangProps {
    id: number;
    namaBarang: string;
    namaKategori: string;
    deskripsi?: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    detailBundling: DetailBundling[] | [];
    favorite: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

const CardBarang = ({
    id,
    namaBarang,
    namaKategori,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    detailBundling,
    favorite,
    hpp,
    multipleHarga,
}: ICardBarangProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { addToCart, cart } = useTransaksi();
    const { openModalDetailBarang } = useDetailBarangModal();

    return (
        <Card
            variant="outlined"
            sx={{
                backgroundColor:
                    (jumlahStok <= 0 && jumlahStok !== null) ||
                    !detailBundling.every(
                        (item) => item.stok === null || item.stok > 0,
                    )
                        ? "#e9f2f0"
                        : undefined,
                marginBottom: 1,
                padding: 2,
            }}
        >
            <Box
                display="grid"
                gridTemplateColumns="1fr auto"
                rowGap={isPhoneScreen ? 1 : 3}
                sx={{
                    alignItems: { xs: "stretch", sm: "center" },
                    justifyContent: {
                        xs: "space-between",
                    },
                }}
            >
                <Stack
                    direction={{ sm: "row" }}
                    sx={{
                        whiteSpace: "nowrap",
                        // overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                    gap={2}
                >
                    <Link
                        onClick={() =>
                            openModalDetailBarang(namaBarang, url, deskripsi)
                        }
                        color="#000000"
                        underline="none"
                        sx={{
                            position: "relative",
                            display: "grid",
                            placeItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: "primary.main",
                                width: {
                                    xs: 60,
                                    sm: 60,
                                    md: 70,
                                    lg: 60,
                                    xl: 80,
                                },
                                height: {
                                    xs: 60,
                                    sm: 60,
                                    md: 70,
                                    lg: 60,
                                    xl: 80,
                                },
                            }}
                            alt={namaBarang}
                            src={url}
                        >
                            {avatarAlt(namaBarang)}
                        </Avatar>
                        {favorite > 0 && (
                            <StarIcon
                                sx={{
                                    width: 24,
                                    height: 24,
                                    position: "absolute",
                                    zIndex: 2,
                                    top: "8px",
                                    left: "70%",
                                    border: 1,
                                    borderColor: "#e7e5e4",
                                    borderRadius: "50%",
                                    padding: isPhoneScreen ? 0.5 : 0.25,
                                    color: "#fbbf24",
                                    bgcolor: "#FFFFFF",
                                }}
                                fontSize="large"
                            />
                        )}
                    </Link>
                    <Link
                        onClick={() =>
                            openModalDetailBarang(namaBarang, url, deskripsi)
                        }
                        color={textPrimary.body}
                        underline="none"
                        sx={{ cursor: "pointer" }}
                    >
                        <Typography
                            mt={isPhoneScreen ? undefined : 1}
                            fontSize={12}
                            color={
                                (jumlahStok < 5 && jumlahStok !== null) ||
                                !detailBundling.every(
                                    (item) =>
                                        item.stok === null || item.stok > 0,
                                )
                                    ? "#ef4444"
                                    : textPrimary.light
                            }
                        >
                            {detailBundling.length > 0
                                ? detailBundling.every(
                                      (item) =>
                                          item.stok === null || item.stok > 0,
                                  )
                                    ? "Otomatis"
                                    : "Ada barang yang habis"
                                : jumlahStok === null
                                ? "Tanpa stok"
                                : jumlahStok > 5
                                ? "Tersedia " + toRibuan(jumlahStok) + " barang"
                                : jumlahStok <= 5 && jumlahStok > 0
                                ? "Barang tersisa " + toRibuan(jumlahStok)
                                : "Barang telah habis"}
                        </Typography>
                        <Tooltip title={namaBarang}>
                            <Typography
                                fontSize={isPhoneScreen ? 14 : 16}
                                fontWeight="bold"
                            >
                                {truncate(namaBarang, 20)}
                            </Typography>
                        </Tooltip>
                        {deskripsi ? (
                            <Tooltip title={deskripsi}>
                                <Typography fontSize={12} color="#76747C">
                                    {truncate(deskripsi, 30)}
                                </Typography>
                            </Tooltip>
                        ) : undefined}
                        {multipleHarga.length > 0 ? (
                            <Typography
                                variant="subtitle2"
                                color="#76747C"
                                fontWeight="bold"
                            >
                                {toRupiah(Number(multipleHarga[0].harga))}
                            </Typography>
                        ) : (
                            <Typography
                                variant="subtitle2"
                                color="#76747C"
                                fontWeight="bold"
                            >
                                {toRupiah(harga)}
                            </Typography>
                        )}
                    </Link>
                </Stack>
                <Button
                    disabled={
                        cart.findIndex((c) => c.id === id) >= 0 ||
                        (jumlahStok <= 0 && jumlahStok !== null) ||
                        !detailBundling.every(
                            (item) => item.stok === null || item.stok > 0,
                        )
                    }
                    variant="contained"
                    onClick={() =>
                        addToCart({
                            id,
                            namaBarang,
                            namaKategori,
                            harga:
                                multipleHarga.length > 0
                                    ? Number(multipleHarga[0].harga)
                                    : harga,
                            url,
                            qty: 1,
                            sku,
                            jumlahStok,
                            detailBundling,
                            hpp,
                            faktur: [],
                            multipleHarga,
                        })
                    }
                    startIcon={
                        cart.findIndex((c) => c.id === id) >= 0 ? (
                            <ShoppingCartIcon />
                        ) : (jumlahStok <= 0 && jumlahStok !== null) ||
                          !detailBundling.every(
                              (item) => item.stok === null || item.stok > 0,
                          ) ? (
                            <RemoveShoppingCartIcon />
                        ) : (
                            <ShoppingCartCheckoutIcon />
                        )
                    }
                    sx={{
                        gridColumn: isPhoneScreen ? "1 / 3" : "unset",
                    }}
                >
                    {cart.findIndex((c) => c.id === id) >= 0
                        ? "Di Keranjang"
                        : (jumlahStok <= 0 && jumlahStok !== null) ||
                          !detailBundling.every(
                              (item) => item.stok === null || item.stok > 0,
                          )
                        ? "Stok Habis"
                        : "Pesan"}
                </Button>
            </Box>
        </Card>
    );
};

export default CardBarang;
