import { createContext, useContext, useState } from "react";
import { GetDetailInvoiceResponse } from "../constants/types";

type DebtState = {
    transaction: GetDetailInvoiceResponse["data"] | null;
    setTransaction: React.Dispatch<
        React.SetStateAction<GetDetailInvoiceResponse["data"] | null>
    >;
    paymentAmount: number;
    setPaymentAmount: React.Dispatch<React.SetStateAction<number>>;
    amountPaid: number;
    setAmountPaid: React.Dispatch<React.SetStateAction<number>>;
    paymentDate: Date | null;
    setPaymentDate: React.Dispatch<React.SetStateAction<Date | null>>;
    extendDueDate: Date | null;
    setExtendDueDate: React.Dispatch<React.SetStateAction<Date | null>>;
    confirmDebtPayment: boolean;
    setConfirmDebtPayment: React.Dispatch<React.SetStateAction<boolean>>;
    resetDebt: () => void;
};

const DebtContext = createContext<DebtState>({} as DebtState);

export function DebtProvider({ children }: { children: React.ReactNode }) {
    const [transaction, setTransaction] = useState<
        GetDetailInvoiceResponse["data"] | null
    >(null);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [paymentDate, setPaymentDate] = useState<Date | null>(new Date());
    const [extendDueDate, setExtendDueDate] = useState<Date | null>(null);
    const [confirmDebtPayment, setConfirmDebtPayment] = useState(false);

    function resetDebt() {
        setTransaction(null);
        setPaymentAmount(0);
        setAmountPaid(0);
        setPaymentDate(new Date());
        setExtendDueDate(null);
    }

    return (
        <DebtContext.Provider
            value={{
                transaction,
                setTransaction,
                paymentAmount,
                setPaymentAmount,
                amountPaid,
                setAmountPaid,
                paymentDate,
                setPaymentDate,
                extendDueDate,
                setExtendDueDate,
                confirmDebtPayment,
                setConfirmDebtPayment,
                resetDebt,
            }}
        >
            {children}
        </DebtContext.Provider>
    );
}

export function useDebt() {
    const ctx = useContext(DebtContext);
    if (ctx === undefined) {
        throw new Error("useDebt must be used within DebtProvider");
    }
    return ctx;
}
