import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
    Autocomplete,
    Link,
    OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import NumberFormat from "react-number-format";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import useIncrementSKU from "../../services/queries/useIncrementSKU";
import {
    AddItemBody,
    AddItemResponse,
    SelectedProductBundling,
    ParamsGetKategori,
    ParamsGetIncrementSKU,
    SubCategoriesParams,
    IOptions,
} from "../../constants/types";
import PilihItemStep from "./PilihItemStep/PilihItemStep";
import DetailItemStep from "./DetailItemStep/DetailItemStep";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useStokModal } from "../../context/stokModalContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { useMutasiStokModal } from "../../context/mutasiStokModalContext";
import formatHarga from "../../utils/formatHarga";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import { Close } from "@mui/icons-material";
import useSubKategori from "../../services/queries/useSubKategori";
import { textPrimary } from "../../constants/colors";
interface IModalStokProps {
    refetchItem: () => void;
    refetchKategori: () => void;
}

const schema = yup.object({
    isActiveStok: yup.boolean(),
    isKategoriBaru: yup.boolean(),
    namaBarang: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama barang terlalu panjang"),
    deskripsiBarang: yup
        .string()
        .nullable()
        .max(500, "Deskripsi terlalu panjang"),
    multipleHarga: yup.array().of(
        yup.object().shape({
            harga: yup.string().required("Kolom wajib diisi"),
            keterangan: yup.string().nullable(),
        }),
    ),
    kategoriId: yup
        .object()
        .shape({
            id: yup.string(),
            nama: yup.string(),
        })
        .when("isKategoriBaru", {
            is: false,
            then: yup
                .object()
                .shape({
                    id: yup.string().required("Kolom wajib diisi"),
                    nama: yup.string().required("Kolom wajib diisi"),
                })
                .required("Kolom wajib diisi"),
        }),
    kategoriBaru: yup.string().when("isKategoriBaru", {
        is: true,
        then: yup
            .string()
            .required("Kolom wajib diisi")
            .max(50, "Kategori baru terlalu panjang"),
    }),
    sku: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "SKU terlalu panjang"),
    jumlahStok: yup
        .string()
        .nullable()
        .when("isActiveStok", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    gambar: yup.mixed(),
    favorite: yup.boolean().required("Kolom wajib diisi"),
    isHPP: yup.boolean(),
    hpp: yup
        .string()
        .nullable()
        .when("isHPP", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
});

const ModalStok = ({ refetchItem, refetchKategori }: IModalStokProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModal, closeModal, dataStok, section, setSection } =
        useStokModal();
    const { openModalMutasiStok } = useMutasiStokModal();
    const { ukmIdUser } = useAuth();

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsKategori] = useState<ParamsGetKategori>(initialParams);
    const { data: dataKategori } = useKategori(paramsKategori);
    const [selectedKategori, setSelectedKategori] = useState<IOptions | null>(
        null,
    );

    const initialSubKategoriParams: SubCategoriesParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
    };
    const [subKategoriParams, setSubKategoriParams] = useState(
        initialSubKategoriParams,
    );
    const { data: dataSubKategori, isLoading: isLoadingSubKategori } =
        useSubKategori(subKategoriParams);

    const initialParamsIncrementSKU = {
        ukmId: ukmIdUser && ukmIdUser[0],
    };
    const [paramsIncrementSKU] = useState<ParamsGetIncrementSKU>(
        initialParamsIncrementSKU,
    );
    const { data: incrementSKU, refetch: refetchSKU } =
        useIncrementSKU(paramsIncrementSKU);
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedProductBundling[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems, handleReset } = useTransaksi();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user } = useUser();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedProductBundling[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaBarang: "",
            deskripsiBarang: "",
            kategoriId: undefined,
            kategoriBaru: "",
            harga: undefined,
            jumlahStok: 1,
            sku: "",
            gambar: null,
            isActiveStok: true,
            isKategoriBaru: false,
            favorite: false,
            multipleHarga: [{ harga: undefined, keterangan: null }],
        }),
        [ukmIdUser],
    );

    const steps = [
        {
            label: "Pilih produk",
            description: `Anda dapat memilih produk lebih dari satu untuk dijadikan satu paket penjualan.`,
        },
        {
            label: "Atur deskripsi dan harga paket",
            description: "Berikan nama dan atur harga paket penjualan Anda.",
        },
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
        setError,
        clearErrors,
        watch,
    } = useForm<AddItemBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: `multipleHarga`,
    });

    const addHarga = () => {
        const newHarga = {
            harga: "",
            keterangan: null,
        };
        append(newHarga);
    };

    const watchSKU = watch("sku");
    const isHPP = watch("isHPP");

    const generateSKU = () =>
        watch("namaBarang") === ""
            ? ""
            : watch("namaBarang")
                  .toUpperCase()
                  .replace(" ", "")
                  .substring(0, 4) +
              "-" +
              incrementSKU?.urutanke;

    const isKategoriBaru = watch("isKategoriBaru");
    const isActiveStok = watch("isActiveStok");

    const onSubmit = async (values: AddItemBody) => {
        console.log(values);
        const formattedHarga = Number(String(values.harga).replace(/\./g, ""));
        const formattedHargaBeli = String(values.hpp).replace(/\./g, "");
        setIsButtonLoading(true);
        if (dataStok) {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
                formData.append("namaBarang", values.namaBarang);
                formData.append(
                    "deskripsiBarang",
                    values.deskripsiBarang === null
                        ? ""
                        : values.deskripsiBarang,
                );
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "kategoriBaru",
                    values.isKategoriBaru === true ? values.kategoriBaru : "",
                );
                formData.append(
                    "kategoriId",
                    values.isKategoriBaru === true
                        ? ""
                        : String(values.kategoriId.id),
                );
                formData.append("harga", String(formattedHarga));
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true
                        ? "1"
                        : values.isActiveStok === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                formData.append(
                    "favorite",
                    values.favorite === true
                        ? "1"
                        : values.favorite === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "isShow",
                    values.isShow === true
                        ? "1"
                        : values.isShow === false
                        ? "0"
                        : "0",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `harga[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `harga[${i}][keterangan]`,
                        String(values.multipleHarga[i].keterangan),
                    );
                }
                formData.append("_method", "put");
                const { data } = await axios.post<AddItemResponse>(
                    `/api/item/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                console.log("Values id: ", values.id);
                console.log("Values namaBarang: ", values.namaBarang);
                if (data.code === 200) {
                    Swal.fire({
                        title: "Barang telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModal();
                }
                setFetchingItems();
                setIsButtonLoading(false);
                refetchItem();
                refetchKategori();
                handleReset();
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<any>;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${serverError.response.data.message}`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(values.ukmId));
                formData.append("namaBarang", values.namaBarang);
                formData.append("deskripsiBarang", values.deskripsiBarang);
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "kategoriBaru",
                    values.isKategoriBaru === true ? values.kategoriBaru : "",
                );
                formData.append(
                    "kategoriId",
                    values.isKategoriBaru === true
                        ? ""
                        : String(values.kategoriId.id),
                );
                formData.append("harga", String(formattedHarga));
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true
                        ? "1"
                        : values.isActiveStok === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                formData.append(
                    "favorite",
                    values.favorite === true
                        ? "1"
                        : values.favorite === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "hargaBeli",
                    values.isHPP === true
                        ? formattedHargaBeli
                        : values.isHPP === false
                        ? "0"
                        : "0",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `harga[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `harga[${i}][keterangan]`,
                        String(values.multipleHarga[i].keterangan),
                    );
                }
                const { data } = await axios.post<AddItemResponse>(
                    `/api/item`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: values.namaBarang + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModal();
                }
                setFetchingItems();
                setIsButtonLoading(false);
                refetchItem();
                refetchKategori();
                handleReset();
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<any>;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${serverError.response.data.message}`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        }
    };

    useEffect(() => {
        if (isOpenModal) {
            if (dataStok) {
                if (dataStok.isBundling > 0) {
                    addProducts(
                        dataStok.detailBundling.map((li) => ({
                            id: li.id,
                            nama: li.namaBarang,
                            harga: li.harga,
                            stok: li.stok,
                        })),
                    );
                    setActiveStep(1);
                    setSection("bundle");
                } else {
                    reset({
                        ...dataStok,
                        multipleHarga: dataStok.hargaMultiple,
                    });
                    setSelectedKategori(
                        dataStok.subKategori
                            ? {
                                  id: dataStok.subKategori.id,
                                  nama: dataStok.subKategori.nama ?? "",
                              }
                            : {
                                  id: 0,
                                  nama: "",
                              },
                    );
                    setSection("single");
                }
                console.log(dataStok);
            } else {
                reset(initialValues);
            }
        }
    }, [isOpenModal, dataStok, reset, initialValues, setSection]);

    useEffect(() => {
        if (isOpenModal) {
            refetchSKU();
        }
    }, [isOpenModal, refetchSKU]);

    useEffect(() => {
        if (!isOpenModal) {
            resetBundlingSteps();
        }
    }, [isOpenModal]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModal}
            onClose={closeModal}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        color={textPrimary.title}
                        fontSize={32}
                        fontWeight="bold"
                    >
                        {dataStok ? "Ubah Barang" : "Tambah Barang"}{" "}
                        {section === "single" ? "Satuan" : "Bundling/Paket"}
                    </Typography>
                    <IconButton onClick={closeModal}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                {section === "single" && (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="namaBarang"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Typography
                                                color={textPrimary.body}
                                                fontWeight={500}
                                            >
                                                Nama Barang
                                            </Typography>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.namaBarang,
                                                )}
                                            >
                                                <OutlinedInput
                                                    id="namaBarang"
                                                    {...field}
                                                    onBlur={() => {
                                                        if (dataStok) {
                                                            field.onBlur();
                                                        } else {
                                                            if (
                                                                watchSKU.length ===
                                                                0
                                                            ) {
                                                                setValue(
                                                                    "sku",
                                                                    generateSKU(),
                                                                );
                                                                clearErrors(
                                                                    "sku",
                                                                );
                                                            }
                                                            field.onBlur();
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                    rules={{ required: "Nama barang required" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="sku"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Typography
                                                color={textPrimary.body}
                                                fontWeight={500}
                                            >
                                                SKU
                                            </Typography>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.sku)}
                                            >
                                                <OutlinedInput
                                                    id="sku"
                                                    {...field}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                    rules={{ required: "SKU required" }}
                                    defaultValue={generateSKU()}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                                <Typography
                                    color={textPrimary.body}
                                    fontWeight={500}
                                >
                                    Multiple Harga
                                </Typography>
                                <Typography
                                    color={textPrimary.light}
                                    fontSize={12}
                                    fontWeight={300}
                                >
                                    Anda dapat memberi beberapa harga berbeda
                                    untuk barang yang sama. Multiple harga hanya
                                    digunakan pada halaman penjualan.
                                </Typography>
                            </Grid>
                            {fields.map((field, idx) => (
                                <React.Fragment key={field.id}>
                                    <Grid item xs={5} sm={4}>
                                        <Controller
                                            name={`multipleHarga.${idx}.harga`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <Typography
                                                        color={textPrimary.body}
                                                        fontWeight={500}
                                                    >
                                                        Harga {idx + 1}
                                                    </Typography>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        allowNegative={false}
                                                        fullWidth
                                                        variant="outlined"
                                                        onKeyDown={(
                                                            e: React.KeyboardEvent,
                                                        ) => {
                                                            if (e.key === "-") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onValueChange={(
                                                            values,
                                                        ) => {
                                                            const { value } =
                                                                values;
                                                            const valueAkhir =
                                                                value.replace(
                                                                    ".",
                                                                    "",
                                                                );
                                                            setValue(
                                                                `multipleHarga.${idx}.harga`,
                                                                Number(
                                                                    valueAkhir,
                                                                ),
                                                            );
                                                        }}
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error &&
                                                            fieldState?.error
                                                                .message
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Typography>
                                                                        Rp.
                                                                    </Typography>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        {...field}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={7}>
                                        <Controller
                                            name={`multipleHarga.${idx}.keterangan`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <Typography
                                                        color={textPrimary.body}
                                                        fontWeight={500}
                                                    >
                                                        Keterangan
                                                    </Typography>
                                                    <TextField
                                                        id={`multipleHarga.${idx}.keterangan`}
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Masukkan Keterangan Harga"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error &&
                                                            fieldState?.error
                                                                .message
                                                        }
                                                        {...field}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    {idx === 0 ? null : (
                                        <Grid item xs={1}>
                                            <Button
                                                variant="contained"
                                                onClick={() => remove(idx)}
                                                size="large"
                                                color="buttonred"
                                                sx={{
                                                    height: "54px",
                                                    marginTop: "1.5rem",
                                                }}
                                            >
                                                <Delete />
                                            </Button>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                            <Grid item xs={3} justifyContent="center">
                                <Button
                                    variant="contained"
                                    onClick={() => addHarga()}
                                    startIcon={<Add />}
                                    color="buttongreen"
                                    fullWidth
                                    sx={{ fontWeight: 500 }}
                                >
                                    Tambah Harga
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    color={textPrimary.body}
                                    fontWeight={500}
                                >
                                    Deskripsi{" "}
                                    <span style={{ color: textPrimary.light }}>
                                        (opsional)
                                    </span>
                                </Typography>
                                <Controller
                                    name="deskripsiBarang"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="deskripsiBarang"
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            error={Boolean(
                                                errors.deskripsiBarang,
                                            )}
                                            helperText={
                                                errors.deskripsiBarang
                                                    ? errors.deskripsiBarang
                                                          .message
                                                    : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Stack mt={2}>
                            <Controller
                                name="gambar"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Typography
                                            color={textPrimary.body}
                                            fontWeight={500}
                                        >
                                            Gambar Barang{" "}
                                            <span
                                                style={{
                                                    color: textPrimary.light,
                                                }}
                                            >
                                                (opsional)
                                            </span>
                                        </Typography>
                                        <TextField
                                            id="gambar"
                                            fullWidth
                                            type="file"
                                            variant="outlined"
                                            inputProps={{
                                                accept: "image/*",
                                            }}
                                            error={Boolean(errors.gambar)}
                                            helperText={
                                                errors.gambar
                                                    ? errors.gambar.message
                                                    : ""
                                            }
                                            {...field}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target.files[0].size <
                                                    2097152
                                                ) {
                                                    console.log(
                                                        event.target.files[0],
                                                    );
                                                    setValue(
                                                        "gambar",
                                                        event.target.files[0],
                                                    );
                                                    clearErrors("gambar");
                                                } else {
                                                    event.target.value = "";
                                                    setError("gambar", {
                                                        type: "manual",
                                                        message:
                                                            "Ukuran gambar harus kurang dari 2MB",
                                                    });
                                                }
                                            }}
                                            value={undefined}
                                        />
                                    </>
                                )}
                            />
                            <Typography variant="caption" color="#6D6D6D">
                                *Resolusi optimal: 300 x 300
                            </Typography>
                            <Typography variant="caption" color="#6D6D6D">
                                *Ukuran maksimal: 2MB
                            </Typography>
                        </Stack>
                        <Grid container marginTop={2}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Kategori Barang
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        Aktifkan opsi jika ingin menambahkan sub
                                        kategori baru
                                    </Typography>
                                </div>
                                <Controller
                                    name="isKategoriBaru"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} />}
                                            label="Tambah Sub Kategori Baru"
                                            componentsProps={{
                                                typography: {
                                                    color: textPrimary.body,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid container marginTop={1} columnSpacing={1}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={dataKategori?.content ?? []}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Kategori"
                                                variant="outlined"
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            String(option.id) ===
                                            String(value.id)
                                        }
                                        getOptionLabel={(option) => option.nama}
                                        fullWidth
                                        value={selectedKategori}
                                        onChange={(_event, newKategori) => {
                                            setSelectedKategori(newKategori);
                                            setSubKategoriParams({
                                                ...subKategoriParams,
                                                subKategori: newKategori
                                                    ? +newKategori.id
                                                    : undefined,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {isKategoriBaru ? (
                                    <Controller
                                        name="kategoriBaru"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                id="kategoriBaru"
                                                label="Sub Kategori Baru"
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.kategoriBaru,
                                                )}
                                                helperText={
                                                    errors.kategoriBaru
                                                        ? errors.kategoriBaru
                                                              .message
                                                        : " "
                                                }
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required:
                                                "Sub kategori baru wajib diisi",
                                        }}
                                    />
                                ) : (
                                    <FormControl
                                        fullWidth
                                        disabled={isKategoriBaru}
                                    >
                                        <Controller
                                            control={control}
                                            name="kategoriId"
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        options={
                                                            dataSubKategori?.content ??
                                                            []
                                                        }
                                                        loading={
                                                            isLoadingSubKategori
                                                        }
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Sub Kategori"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            String(
                                                                option.id,
                                                            ) ===
                                                            String(value.id)
                                                        }
                                                        getOptionLabel={(
                                                            option,
                                                        ) => option.nama}
                                                        fullWidth
                                                        value={value}
                                                        onChange={(
                                                            _event,
                                                            newKategori: IOptions | null,
                                                        ) => {
                                                            onChange(
                                                                newKategori,
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                        <FormHelperText
                                            error={Boolean(errors.kategoriId)}
                                        >
                                            {errors.kategoriId
                                                ? errors.kategoriId.id?.message
                                                : " "}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            display={
                                !!dataStok
                                    ? "none"
                                    : ukmData?.isPro === 0
                                    ? "none"
                                    : undefined
                            }
                            container
                            alignItems="center"
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Harga Pokok Penjualan (HPP)
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        Nonaktikan opsi jika barang tidak
                                        membutuhkan harga pokok penjualan (HPP)
                                    </Typography>
                                </div>
                                <Controller
                                    name="isHPP"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                    sx={{
                                                        "& .MuiSwitch-thumb": {
                                                            color: "#eaeaea",
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                field.value
                                                    ? "HPP Aktif"
                                                    : "HPP Tidak Aktif"
                                            }
                                            labelPlacement="start"
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            display={
                                !!dataStok
                                    ? "none"
                                    : ukmData?.isPro === 0
                                    ? "none"
                                    : undefined
                            }
                            container
                            marginY={1}
                        >
                            <Grid item xs={12}>
                                {isHPP ? (
                                    <Controller
                                        name="hpp"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                allowNegative={false}
                                                fullWidth
                                                variant="outlined"
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    const valueAkhir =
                                                        value.replace(".", "");
                                                    console.log(valueAkhir);
                                                    setValue(
                                                        "hpp",
                                                        Number(valueAkhir),
                                                    );
                                                }}
                                                error={Boolean(errors.hpp)}
                                                helperText={
                                                    errors.hpp
                                                        ? errors.hpp.message
                                                        : " "
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Stok Barang
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        Anda dapat melakukan perubahan jumlah
                                        stok melalui fitur Mutasi Stok.{" "}
                                        <Link
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                closeModal();
                                                openModalMutasiStok();
                                            }}
                                            underline="hover"
                                            sx={{ cursor: "pointer" }}
                                        >
                                            Ke Mutasi Stok
                                        </Link>
                                    </Typography>
                                </div>
                                <Controller
                                    name="isActiveStok"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={!!dataStok}
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                    sx={{
                                                        "& .MuiSwitch-thumb": {
                                                            color: "#eaeaea",
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                field.value
                                                    ? "Stok Aktif"
                                                    : "Stok Tidak Aktif"
                                            }
                                            labelPlacement="start"
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            container
                            marginTop={1}
                            marginBottom={2}
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                {isActiveStok ? (
                                    <Controller
                                        name="jumlahStok"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                disabled={!!dataStok}
                                                margin="dense"
                                                id="jumlahStok"
                                                placeholder="Masukkan jumlah stok barang"
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.jumlahStok,
                                                )}
                                                helperText={
                                                    errors.jumlahStok
                                                        ? errors.jumlahStok
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: "Jumlah stok required",
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" mb={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Favorit
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        Aktifkan opsi jika ingin menampilkan
                                        barang di urutan paling awal
                                    </Typography>
                                </div>
                                <Controller
                                    name="favorite"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Favorit Aktif"
                                                        : "Favorit Nonaktif"
                                                }
                                                labelPlacement="start"
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        {dataStok && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Tampilkan
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        Nonaktifkan jika tidak ingin menampilkan
                                        barang pada transaksi.
                                    </Typography>
                                </div>
                                <Grid item>
                                    <Controller
                                        name="isShow"
                                        control={control}
                                        render={({ field }) => (
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={
                                                                field.value
                                                            }
                                                            onChange={
                                                                field.onChange
                                                            }
                                                            sx={{
                                                                "& .MuiSwitch-thumb":
                                                                    {
                                                                        color: "#eaeaea",
                                                                    },
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        field.value
                                                            ? "Tampilkan"
                                                            : "Tidak Tampilkan"
                                                    }
                                                    labelPlacement="start"
                                                    componentsProps={{
                                                        typography: {
                                                            variant: "body2",
                                                        },
                                                    }}
                                                />
                                            </FormGroup>
                                        )}
                                    />
                                </Grid>
                            </Stack>
                        )}
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={2}
                            pt={2}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => closeModal()}
                                fullWidth={!isDesktopScreen}
                            >
                                Kembali
                            </Button>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                type="submit"
                                fullWidth={!isDesktopScreen}
                            >
                                Simpan
                            </LoadingButton>
                        </Stack>
                    </Fragment>
                )}

                {section === "bundle" && (
                    <Fragment>
                        <Grid>
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                            >
                                {steps.map((step, index) => (
                                    <Step key={String(index + 1)}>
                                        <StepLabel
                                            sx={{
                                                color: "#7a1a8a",
                                                fontSize: "1.5rem",
                                                fontWeight: 600,
                                            }}
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography
                                                color={textPrimary.body}
                                            >
                                                {step.description}
                                            </Typography>
                                            <Box my={4}>
                                                {index === 0 && (
                                                    <Fragment>
                                                        <PilihItemStep
                                                            addProducts={
                                                                addProducts
                                                            }
                                                        />
                                                        <Box
                                                            mt={4}
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                            gap="1rem"
                                                        >
                                                            <Button
                                                                disabled={
                                                                    index === 0
                                                                }
                                                                onClick={
                                                                    handleBack
                                                                }
                                                                variant="outlined"
                                                            >
                                                                Kembali
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                disabled={
                                                                    activeStep ===
                                                                        0 &&
                                                                    products.length ===
                                                                        0
                                                                }
                                                                onClick={
                                                                    handleNext
                                                                }
                                                            >
                                                                Lanjutkan
                                                            </Button>
                                                        </Box>
                                                    </Fragment>
                                                )}
                                                {index === 1 && (
                                                    <DetailItemStep
                                                        products={products}
                                                        onBack={handleBack}
                                                        dataStokBundle={{
                                                            id: dataStok?.id,
                                                            item: dataStok?.detailBundling.map(
                                                                (li) => ({
                                                                    itemId: li.id,
                                                                    jumlahItem:
                                                                        li.jumlahYangDiambil,
                                                                }),
                                                            ) ?? [
                                                                {
                                                                    itemId: 1,
                                                                    jumlahItem: 1,
                                                                },
                                                            ],
                                                            namaBundling:
                                                                dataStok?.namaBarang ??
                                                                "",
                                                            deskripsiBundling:
                                                                dataStok?.deskripsiBarang ??
                                                                "",
                                                            kategoriBundling:
                                                                dataStok
                                                                    ?.kategoriId
                                                                    .id ?? "",
                                                            sku:
                                                                dataStok?.sku ??
                                                                "",
                                                            hargaBundling:
                                                                String(
                                                                    dataStok?.harga,
                                                                ) ?? "",
                                                            hargaMultiple:
                                                                dataStok?.hargaMultiple ??
                                                                [],
                                                            isActiveStok:
                                                                dataStok?.isActiveStok ??
                                                                true,
                                                            jumlahStok:
                                                                dataStok?.jumlahStok ??
                                                                1,
                                                            favorite:
                                                                dataStok?.favorite ??
                                                                false,
                                                            isShow:
                                                                dataStok?.isShow ??
                                                                false,
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Fragment>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ModalStok;
