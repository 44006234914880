import { useQuery } from "react-query";
import { PelangganParams, PelangganResponse } from "../../constants/types";
import axios from "../axios";

export default function usePelanggan(params: PelangganParams) {
    return useQuery(
        ["pelanggan", params],
        async () => {
            const res = await axios.get<PelangganResponse>("/api/pelanggans", {
                params,
            });
            return res.data.data;
        },
        {
            enabled: params.ukmId !== undefined,
        },
    );
}
